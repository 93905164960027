import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import { generateBusinessId } from "../Tools/generateBusinessId";
import dayjs from "dayjs";
import { Rings } from "react-loader-spinner";

export default function SignUp(props) {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState("");
  const [selectedMembershipTier, setSelectedMembershipTier] = useState(0);
  const [businessName, setBusinessName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [serviceAreaZipcode, setServiceAreaZipcode] = useState("");
  const [serviceAreaRadius, setServiceAreaRadius] = useState(0);
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerPhoneUnformatted, setOwnerPhoneUnformatted] = useState("");
  const [supportPhoneUnformatted, setSupportPhoneUnformatted] = useState("");
  const [supportPhone, setSupportPhone] = useState("");
  const [prevValue, setPrevValue] = useState("");
  const [prevSupportValue, setPrevSupportValue] = useState("");
  const [loading, setLoading] = useState(false);

  const db = firebase.firestore();

  /*

  const subscriptionOptions = [
    {
      subscriptionName: "Starter Plan",
      subscriptionPrice: "300",
      color: "rgba(123, 223, 242, 0.565)",
      subscriptionFeatures: [
        { itemName: "Up To 100 Customers" },
        { itemName: "Unlimited Workers" },
        { itemName: "Web App Access" },
        { itemName: "Worker Mobile App Access" },
        { itemName: "Customer Mobile App Access" },
      ],
    },
    {
      subscriptionName: "Advanced Plan",
      subscriptionPrice: "500",
      color: "#CAFF8A90",
      subscriptionFeatures: [
        { itemName: "Up To 300 Customers" },
        { itemName: "Unlimited Workers" },
        { itemName: "Web App Access" },
        { itemName: "Worker Mobile App Access" },
        { itemName: "Customer Mobile App Access" },
      ],
    },
    {
      subscriptionName: "Ultimate Plan",
      subscriptionPrice: "700",
      color: "#796DFE90",
      subscriptionFeatures: [
        { itemName: "Up To 600 Customers" },
        { itemName: "Unlimited Workers" },
        { itemName: "Web App Access" },
        { itemName: "Worker Mobile App Access" },
        { itemName: "Customer Mobile App Access" },
      ],
    },
  ];

  */

  const defaultChemicals = [
    {
      chemicalPricePerDose: "$6.70",
      chemicalId: "0e82163c-c2c6-42a7-85a5-3de913765d3d",
      chemicalName: "Salt",
      chemicalDose: "1 bag",
    },
    {
      chemicalDose: "1 gallon",
      chemicalPricePerDose: "$3.84",
      chemicalId: "f22e6a8c-c8a9-4334-bcfa-326f59d10cb7",
      chemicalName: "Muriatic Acid",
    },
    {
      chemicalDose: "1 scoop",
      chemicalId: "89597ddc-d622-4762-9ed2-49602505ea5a",
      chemicalName: "Calcium Hypochlorite",
      chemicalPricePerDose: "$6.17",
    },
    {
      chemicalDose: "Per tab",
      chemicalName: "Tabs",
      chemicalPricePerDose: "$0.84",
      chemicalId: "5e5993f6-0b9b-4ac9-80fd-7b928903c529",
    },
    {
      chemicalPricePerDose: "$1.50",
      chemicalId: "b9e5c7ee-dfad-42f7-8618-f89dd8ad334f",
      chemicalDose: "1 scoop",
      chemicalName: "Sodium Bicarb",
    },
    {
      chemicalName: "DE",
      chemicalPricePerDose: "$0.51",
      chemicalId: "951b905a-db5c-4e39-b1a5-605737f3675e",
      chemicalDose: "1 scoop",
    },
    {
      chemicalDose: "1 scoop",
      chemicalName: "Conditioner",
      chemicalPricePerDose: "$2.34",
      chemicalId: "f9955daa-c31d-4e2e-bef9-83c7145e8eca",
    },
  ];

  const handleCompleteSignup = async () => {
    const newBusinessId = generateBusinessId();

    // create customer
    // add card to customer
    // add sub to customer
    setLoading(true);
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(ownerEmail, password)
        .then((ownerUserCredential) => {
          db.collection("Users")
            .doc(ownerUserCredential.user.uid)
            .set({
              name: ownerName,
              email: ownerEmail,
              uid: ownerUserCredential.user.uid,
              businessId: newBusinessId,
              userType: "owner",
            })
            .then(() => {
              db.collection("Businesses")
                .doc(newBusinessId)
                .set({
                  name: businessName,
                  email: ownerEmail,
                  ownerName: ownerName,
                  customerServiceEmployee: ownerUserCredential.user.uid,
                  ownerPhone: ownerPhone,
                  supportPhone: supportPhone,
                  id: newBusinessId,
                  uid: ownerUserCredential.user.uid,
                  customerCount: 0,
                  poolsCleanedToday: 0,
                  poolsCleanedThisWeek: 0,
                  poolsCleanedThisMonth: 0,
                  notifications: [],
                  threadIds: [],
                  serviceAreaZipcode: serviceAreaZipcode,
                  serviceAreaRadius: serviceAreaRadius,
                  membershipTier: selectedMembershipTier,
                  maxCustomers: 5000, // for now until we introduce subscriptions with tiers
                })
                .then(() => {
                  let departmentId = generateBusinessId();

                  for (
                    let index = 0;
                    index < defaultChemicals.length;
                    index++
                  ) {
                    const chemical = defaultChemicals[index];
                    db.collection("Businesses")
                      .doc(newBusinessId)
                      .collection("Chemicals")
                      .doc(chemical.chemicalId)
                      .set({
                        chemicalId: chemical.chemicalId,
                        chemicalName: chemical.chemicalName,
                        chemicalDose: chemical.chemicalDose,
                        chemicalPricePerDose: chemical.chemicalPricePerDose,
                      })
                      .catch((e) => {
                        alert(e);
                      });
                  }

                  db.collection("Businesses")
                    .doc(newBusinessId)
                    .collection("Finances")
                    .doc(newBusinessId)
                    .set({
                      totalEarnings: 0,
                      earningsThisMonth: 0,
                      totalPaidToWorkers: 0,
                      totalUnpaidToWorkers: 0,
                      paidInvoices: [],
                      unpaidInvoices: [],
                      subscriptions: [],
                      settings: {
                        paySchedule: "monthly",
                        taxRate: "5.0",
                      },
                    })
                    .then(async () => {
                      await db
                        .collection("Businesses")
                        .doc(newBusinessId)
                        .collection("TaskCategories")
                        .doc("Folders")
                        .set({ folders: ["new"] }, { merge: true });

                      db.collection("Businesses")
                        .doc(newBusinessId)
                        .collection("WorkersLocationData")
                        .doc("WorkersLocationData")
                        .set({
                          activeWorkers: [],
                        })
                        .then(() => {
                          // need to add owner account as an employee

                          db.collection("Businesses")
                            .doc(newBusinessId)
                            .collection("Workers")
                            .doc(ownerUserCredential.user.uid)
                            .set({
                              userType: "owner",
                              name: ownerName.toLowerCase(),
                              email: ownerEmail,
                              uid: ownerUserCredential.user.uid,
                              businessId: newBusinessId,
                              poolsCleanedThisMonth: 0,
                              threadIds: [],
                              connections: [],
                              department: {
                                departmentName: "Owners",
                                departmentId: departmentId,
                              },
                              payRate: "0",
                              payMethod: "weekly",
                              unpaidEarnings: 0,
                              uninvoicedEarnings: 0,
                              totalEarnings: 0,
                              feedbackRatings: [],
                              ratingScore: 0,
                              pending: true,
                              phone: ownerPhone,
                              profilePhoto:
                                "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef",
                            })
                            .then(() => {
                              db.collection("Businesses")
                                .doc(newBusinessId)
                                .collection("Workers")
                                .doc(ownerUserCredential.user.uid)
                                .collection("PoolsCleanedPayPeriod")
                                .doc("Pools")
                                .set({
                                  poolsCleanedThisMonthData: [],
                                })
                                .then(() => {
                                  db.collection("Businesses")
                                    .doc(newBusinessId)
                                    .collection("Departments")
                                    .doc(departmentId)
                                    .set({
                                      businessId: newBusinessId,
                                      departmentId: departmentId,
                                      threadIds: [],
                                      departmentMembers: [
                                        ownerUserCredential.user.uid,
                                      ],
                                      departmentName: "Owners",
                                      departmentPermissions: [
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5",
                                        "6",
                                        "7",
                                        "8",
                                        "9",
                                        "10",
                                        "11",
                                        "12",
                                        "13",
                                        "14",
                                        "15",
                                        "16",
                                        "17",
                                        "18",
                                        "19",
                                        "20",
                                        "21",
                                        "22",
                                        "23",
                                        "24",
                                        "25",
                                        "26",
                                        "27",
                                        "28",
                                        "29",
                                        "30",
                                        "31",
                                        "32",
                                        "33",
                                      ],
                                    })
                                    .then(() => {
                                      // Create default route and then add owner
                                      const newRouteId = generateBusinessId();

                                      db.collection("Businesses")
                                        .doc(newBusinessId)
                                        .collection("Routes")
                                        .doc(newRouteId)
                                        .set({
                                          active: true,
                                          routeId: newRouteId,
                                          routeName: "Default Route",
                                          startOn: dayjs(new Date()).format(
                                            "MM/DD/YYYY"
                                          ),
                                          stopAfter: "",
                                          unactivatedChanges: false,
                                          businessId: newRouteId,
                                        })
                                        .then(() => {
                                          db.collection("Businesses")
                                            .doc(newBusinessId)
                                            .collection("Routes")
                                            .doc(newRouteId)
                                            .collection("Employees")
                                            .doc(ownerUserCredential.user.uid)
                                            .get()
                                            .then((documentSnapshot) => {
                                              if (!documentSnapshot.exists) {
                                                db.collection("Businesses")
                                                  .doc(newBusinessId)
                                                  .collection("Routes")
                                                  .doc(newRouteId)
                                                  .collection("Employees")
                                                  .doc(
                                                    ownerUserCredential.user.uid
                                                  )
                                                  .set({
                                                    name: ownerName.toLowerCase(),
                                                    uid: ownerUserCredential
                                                      .user.uid,
                                                    businessId: newBusinessId,
                                                    photo:
                                                      "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef",
                                                    mon: true,
                                                    tue: true,
                                                    wed: true,
                                                    thu: true,
                                                    fri: true,
                                                    sat: true,
                                                    sun: true,
                                                  })
                                                  .then(() => {
                                                    db.collection("Businesses")
                                                      .doc(newBusinessId)
                                                      .collection("Routes")
                                                      .doc(newRouteId)
                                                      .collection("Employees")
                                                      .doc(
                                                        ownerUserCredential.user
                                                          .uid
                                                      )
                                                      .collection("Routes")
                                                      .doc("Pending")
                                                      .set({
                                                        pending: true,
                                                      });

                                                    const subscriptionId =
                                                      generateBusinessId();
                                                    const stopTemplateId =
                                                      generateBusinessId();
                                                    const subscriptionCode =
                                                      Math.floor(
                                                        1000 +
                                                          Math.random() * 9000
                                                      );
                                                    //createRecurringStopTemplate need to creating recurring stop if true

                                                    //create recurring stop template
                                                    db.collection("Businesses")
                                                      .doc(newBusinessId)
                                                      .collection(
                                                        "RecurringStopTemplates"
                                                      )
                                                      .doc(stopTemplateId)
                                                      .set({
                                                        stopName:
                                                          "Full Service Maintenance",
                                                        stopTemplateId:
                                                          stopTemplateId,
                                                        recurringSchedule:
                                                          "Weekly",
                                                        workerDescription:
                                                          "Full Service Maintenance",
                                                        customerDescription:
                                                          "Full Service Maintenance",
                                                        estimatedLaborTime: 10,
                                                        stopTasks: [
                                                          {
                                                            id: "227fd75c-6f5b-48a8-ac53-ea664ab02dfb",
                                                            name: "Sample Task",
                                                            type: "stopTask",
                                                            value:
                                                              "Sample Task",
                                                            visible: true,
                                                          },
                                                        ],
                                                        stopTests: [
                                                          {
                                                            name: "Total Hardness",
                                                            chemicalId: "none",
                                                            id: generateBusinessId(),
                                                            value:
                                                              "Total Hardness",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Total Chlorine",
                                                            chemicalId: "none",
                                                            id: generateBusinessId(),
                                                            value:
                                                              "Total Chlorine",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Free Chlorine",
                                                            chemicalId: "none",
                                                            id: generateBusinessId(),
                                                            value:
                                                              "Free Chlorine",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Ph",
                                                            chemicalId: "none",
                                                            id: generateBusinessId(),
                                                            value: "Ph",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Alkalinity",
                                                            chemicalId: "none",
                                                            id: generateBusinessId(),
                                                            value: "Alkalinity",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Cyanuric Acid",
                                                            chemicalId: "none",
                                                            id: generateBusinessId(),
                                                            value:
                                                              "Cyanuric Acid",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Salt",
                                                            chemicalId: "none",
                                                            id: generateBusinessId(),
                                                            value: "Salt",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                        ],
                                                        stopChemicals: [
                                                          {
                                                            name: "Salt",
                                                            chemicalId:
                                                              "0e82163c-c2c6-42a7-85a5-3de913765d3d",
                                                            id: "0e82163c-c2c6-42a7-85a5-3de913765d3d",
                                                            value:
                                                              "Salt (1 bag)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Muriatic Acid",
                                                            chemicalId:
                                                              "f22e6a8c-c8a9-4334-bcfa-326f59d10cb7",
                                                            id: "131a328f-d553-4d9d-973d-1bd7904a897b",
                                                            value:
                                                              "Muriatic Acid (1 gallon)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Calcium Hypochlorite",
                                                            chemicalId:
                                                              "89597ddc-d622-4762-9ed2-49602505ea5a",
                                                            id: "89597ddc-d622-4762-9ed2-49602505ea5a",
                                                            value:
                                                              "Calcium Hypochlorite (1 scoop)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Tabs",
                                                            chemicalId:
                                                              "5e5993f6-0b9b-4ac9-80fd-7b928903c529",
                                                            id: "5e5993f6-0b9b-4ac9-80fd-7b928903c529",
                                                            value:
                                                              "Tabs (Per tab)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Sodium Bicarb",
                                                            chemicalId:
                                                              "b9e5c7ee-dfad-42f7-8618-f89dd8ad334f",
                                                            id: "612aa32e-7cd3-4481-8904-0f49b14b5c75",
                                                            value:
                                                              "Sodium Bicarb (1 scoop)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "DE",
                                                            chemicalId:
                                                              "951b905a-db5c-4e39-b1a5-605737f3675e",
                                                            id: "621f6d14-9b45-476e-b102-0287759f6811",
                                                            value:
                                                              "DE (1 scoop)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Conditioner",
                                                            chemicalId:
                                                              "f9955daa-c31d-4e2e-bef9-83c7145e8eca",
                                                            id: "0d71999b-d53a-4948-8503-d8107a37f47f",
                                                            value:
                                                              "Conditioner (1 scoop)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                        ],
                                                        stopReport: [
                                                          {
                                                            name: "DE (24lb bag)",
                                                            chemicalId:
                                                              "951b905a-db5c-4e39-b1a5-605737f3675e",
                                                            id: "76325e2a-1b2e-43a4-a4ed-906007d38276",
                                                            value:
                                                              "DE (24lb bag)",
                                                            type: "stopTests",
                                                            visible: true,
                                                          },
                                                          {
                                                            name: "Sodium Bicarb (50lb)",
                                                            chemicalId:
                                                              "b9e5c7ee-dfad-42f7-8618-f89dd8ad334f",
                                                            id: "b3b354c6-adf4-4a25-898d-933e422218af",
                                                            value:
                                                              "Sodium Bicarb (50lb)",
                                                            type: "stopTests",
                                                            visible: true,
                                                          },
                                                          {
                                                            name: "Liquid Chlorine (HASA)",
                                                            chemicalId:
                                                              "d53278f3-8965-4355-a269-e391f6c3c900",
                                                            id: "3e0d5d92-f37c-4579-8246-5c8cbf6595b2",
                                                            value:
                                                              "Liquid Chlorine (HASA)",
                                                            type: "stopTests",
                                                            visible: true,
                                                          },
                                                          {
                                                            name: "Tabs (50lb)",
                                                            chemicalId:
                                                              "dc948ca4-b879-4e4d-b93c-d672d5fcee13",
                                                            id: "171ce099-59f3-40fc-bdd1-4ac9d7316f0d",
                                                            value:
                                                              "Tabs (50lb)",
                                                            type: "stopTests",
                                                            visible: true,
                                                          },
                                                          {
                                                            name: "Liquid Chlorine x 4 (Disposable)",
                                                            chemicalId:
                                                              "de7b476c-444f-4dbf-8f2c-7ef0149ef007",
                                                            id: "abb59b02-f786-42e8-a388-a8ba065a2751",
                                                            value:
                                                              "Liquid Chlorine x 4 (Disposable)",
                                                            type: "stopTests",
                                                            visible: true,
                                                          },
                                                          {
                                                            name: "Black Algaetrine (1 bottle)",
                                                            chemicalId:
                                                              "dfb7b02f-7a55-403c-9c94-ebc5ddd24d16",
                                                            id: "b48aae18-bbc4-41fc-8aad-b3fa13554d1c",
                                                            value:
                                                              "Black Algaetrine (1 bottle)",
                                                            type: "stopTests",
                                                            visible: true,
                                                          },
                                                          {
                                                            name: "Sodium Bicarb (50lb)",
                                                            chemicalId:
                                                              "b9e5c7ee-dfad-42f7-8618-f89dd8ad334f",
                                                            id: "612aa32e-7cd3-4481-8904-0f49b14b5c75",
                                                            value:
                                                              "Sodium Bicarb (50lb)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "DE (24lb bag)",
                                                            chemicalId:
                                                              "951b905a-db5c-4e39-b1a5-605737f3675e",
                                                            id: "621f6d14-9b45-476e-b102-0287759f6811",
                                                            value:
                                                              "DE (24lb bag)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Liquid Chlorine (HASA)",
                                                            chemicalId:
                                                              "d53278f3-8965-4355-a269-e391f6c3c900",
                                                            id: "4d26615e-6e62-4769-af9f-5f2039d14433",
                                                            value:
                                                              "Liquid Chlorine (HASA)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Tabs (50lb)",
                                                            chemicalId:
                                                              "dc948ca4-b879-4e4d-b93c-d672d5fcee13",
                                                            id: "6c3ff66f-3ca8-45a2-8d51-4a50440ac7dd",
                                                            value:
                                                              "Tabs (50lb)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Liquid Chlorine x 4 (Disposable)",
                                                            chemicalId:
                                                              "de7b476c-444f-4dbf-8f2c-7ef0149ef007",
                                                            id: "adfc6f0b-177b-49ef-8195-4fb241edaa16",
                                                            value:
                                                              "Liquid Chlorine x 4 (Disposable)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Black Algaetrine (1 bottle)",
                                                            chemicalId:
                                                              "dfb7b02f-7a55-403c-9c94-ebc5ddd24d16",
                                                            id: "713398b5-3d53-441c-9c79-f53e2b1f1170",
                                                            value:
                                                              "Black Algaetrine (1 bottle)",
                                                            type: "stopTests",
                                                            visible: false,
                                                          },
                                                          {
                                                            name: "Sample Task",
                                                            value:
                                                              "Sample Task",
                                                            id: "227fd75c-6f5b-48a8-ac53-ea664ab02dfb",
                                                            type: "stopTask",
                                                            visible: true,
                                                          },
                                                        ],
                                                        stopMinimumPictures: 1,
                                                        stopIntervalPerWeek: 1,
                                                        stopServiceInterval:
                                                          "Weekly",
                                                        connectedSubscriptionId:
                                                          subscriptionId,
                                                      })
                                                      .then(() => {
                                                        db.collection(
                                                          "Businesses"
                                                        )
                                                          .doc(newBusinessId)
                                                          .collection(
                                                            "Subscriptions"
                                                          )
                                                          .doc(subscriptionId)
                                                          .set({
                                                            subscriptionTitle:
                                                              "Full Service Maintenance",
                                                            subscriptionDescription:
                                                              "Full Service Maintenance",
                                                            subscriptionPrice:
                                                              "199",
                                                            subscriptionTaxEnabled: true,
                                                            subscriptionId:
                                                              subscriptionId,
                                                            subscriptionCode:
                                                              subscriptionCode,
                                                            subscriptionChargeInterval:
                                                              "Weekly",
                                                            subscriptionChemicalsIncluded: true,
                                                            connectedStopTemplateId:
                                                              stopTemplateId,
                                                          })
                                                          .then(() => {
                                                            fetch(
                                                              "https://us-central1-symbri-production.cloudfunctions.net/handleNewBusinessCreation",
                                                              {
                                                                method: "POST",
                                                                body: JSON.stringify(
                                                                  {
                                                                    businessEmail:
                                                                      businessEmail,
                                                                    ownerPhoneNumber:
                                                                      ownerPhoneUnformatted,
                                                                    businessName:
                                                                      businessName,
                                                                    businessPass:
                                                                      password,
                                                                    businessUid:
                                                                      ownerUserCredential
                                                                        .user
                                                                        .uid,
                                                                    ownerEmail:
                                                                      ownerEmail,
                                                                    ownerName:
                                                                      ownerName,
                                                                    ownerUid:
                                                                      ownerUserCredential
                                                                        .user
                                                                        .uid,
                                                                  }
                                                                ),
                                                              }
                                                            );

                                                            firebase
                                                              .auth()
                                                              .signInWithEmailAndPassword(
                                                                ownerEmail,
                                                                password
                                                              )
                                                              .then(
                                                                (
                                                                  userCredential
                                                                ) => {
                                                                  //add owner info to db as an employee but make them a part of the owners department.
                                                                  //need to also have businesses create owners department right away and add the owner account to it
                                                                  userCredential.user
                                                                    .getIdTokenResult()
                                                                    .then(
                                                                      (
                                                                        idTokenResult
                                                                      ) => {
                                                                        if (
                                                                          idTokenResult
                                                                            .claims
                                                                            .business
                                                                        ) {
                                                                          props.setUserAuth(
                                                                            true
                                                                          );
                                                                        } else {
                                                                          db.collection(
                                                                            "Users"
                                                                          )
                                                                            .doc(
                                                                              userCredential
                                                                                .user
                                                                                .uid
                                                                            )
                                                                            .get()
                                                                            .then(
                                                                              (
                                                                                documentSnapshot
                                                                              ) => {
                                                                                const userData =
                                                                                  documentSnapshot.data();
                                                                                console.log(
                                                                                  userData.userType
                                                                                );
                                                                                if (
                                                                                  userData.userType ===
                                                                                    "worker" ||
                                                                                  userData.userType ===
                                                                                    "owner" ||
                                                                                  userData.userType ===
                                                                                    "business"
                                                                                ) {
                                                                                  props.setUserAuth(
                                                                                    true
                                                                                  );

                                                                                  props.setBusinessName(
                                                                                    businessName
                                                                                  );
                                                                                  props.setBusinessId(
                                                                                    userData.businessId
                                                                                  );
                                                                                  setLoading(
                                                                                    false
                                                                                  );
                                                                                } else {
                                                                                  toast.warn(
                                                                                    "You cannot login in here with that account."
                                                                                  );
                                                                                }
                                                                              }
                                                                            );
                                                                        }
                                                                      }
                                                                    );
                                                                }
                                                              )
                                                              .catch(
                                                                (error) => {
                                                                  const errorMessage =
                                                                    error.message;
                                                                  toast.warn(
                                                                    errorMessage
                                                                  );
                                                                }
                                                              );
                                                          });
                                                      });

                                                    // create default subscription for business
                                                  });
                                              }
                                            });
                                        });
                                    })
                                    .catch((e) => {
                                      console.log(e);
                                      alert(e);
                                    });
                                })
                                .catch((e) => {
                                  console.log(e);
                                  alert(e);
                                });
                            })
                            .catch((e) => {
                              console.log(e);
                              alert(e);
                            });
                        })
                        .catch((e) => {
                          console.log(e);
                          alert(e);
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                      alert(e);
                    });
                });
            })
            .catch((e) => {
              console.log(e);
              alert(e);
            });
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } catch (error) {
      alert(error);
    }
  };

  function numericStringMask(str, mask) {
    if (!mask) return str;

    const numeric = str.replaceAll(/[^\d]/g, "");

    let idx = 0;

    const formated = mask.split("").map((el) => {
      if (el === "#") {
        el = numeric[idx];
        idx++;
      }
      return el;
    });

    return formated.join("");
  }

  const validatePassword = (password) => {
    var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;
    return re.test(password);
  };

  const validateEmail = (emailText) => {
    var pattern =
      /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
    if (pattern.test(emailText)) {
      return true;
    } else {
      alert("Bad email address: " + emailText);
      return false;
    }
  };

  function isValidUSZipCode(zipCode) {
    // Regular expression for 5-digit and 9-digit US ZIP codes
    const zipCodeRegex = /^(\d{5})(-\d{4})?$/;

    // Test the provided zipCode against the regular expression
    const isValid = zipCodeRegex.test(zipCode);

    return isValid;
  }

  const checkBeforeSignup = async () => {
    return new Promise((res) => {
      if (businessName === "" || businessName.trim().length === 0) {
        toast.warn("Business name field required!");
        res(500);
        return;
      } else if (ownerPhone === "" || ownerPhone.trim().length === 0) {
        toast.warn("Owner phone field required!");
        res(500);
        return;
      } else if (ownerName === "" || ownerName.trim().length === 0) {
        toast.warn("Owner name field required!");
        res(500);
        return;
      } else if (password === "" || password.trim().length === 0) {
        toast.warn("Password field required!");
        res(500);
        return;
      } else if (ownerEmail === "" || ownerEmail.trim().length === 0) {
        toast.warn("Owner email field required!");
        res(500);
        return;
      } else if (
        confirmPassword === "" ||
        confirmPassword.trim().length === 0
      ) {
        toast.warn("Confirm password field required!");
        res(500);
        return;
      } else if (!isValidUSZipCode(serviceAreaZipcode)) {
        toast.warn("Invalid zipcode!");
        res(500);
        return;
      }

      if (!validateEmail(ownerEmail)) {
        toast.warn("Owner email format invalid!");
        res(500);
        return;
      }

      if (validatePassword(password)) {
        if (confirmPassword === password) {
          // send response of success
          res(200);
        } else {
          toast.warn("Passwords do not match!");
          res(500);
        }
      } else {
        toast.warn(
          "Password format invalid. Requires atleast 1 upper case letter, atleast 1 lower case letter, atleast  1 digit, atleast 1 special characer, and a minimum of 8 characters!"
        );
        res(500);
      }
    });
  };

  const handleEvent = () => {
    checkBeforeSignup().then((res) => {
      if (res === 200) {
        handleCompleteSignup();
      }
    });
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleOwnerPhone = (value) => {
    setOwnerPhoneUnformatted(value);

    // Check if the user is deleting characters
    if (value.length < prevValue.length) {
      setOwnerPhone(value);
    } else {
      let formattedNumber = numericStringMask(value, "(###) ### - ####");
      console.log(formattedNumber);
      setOwnerPhone(formattedNumber);
    }

    // Update the previous value state
    setPrevValue(value);
  };

  const handleSupportPhone = (value) => {
    setSupportPhoneUnformatted(value);

    // Check if the user is deleting characters
    if (value.length < prevValue.length) {
      setSupportPhone(value);
    } else {
      let formattedNumber = numericStringMask(value, "(###) ### - ####");
      console.log(formattedNumber);
      setSupportPhone(formattedNumber);
    }

    // Update the previous value state
    setPrevSupportValue(value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        static
        as="div"
        className="relative z-10"
        onClose={() => console.log()}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-600 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            Sign Up{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Join symbri by completing the signup form below!
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Business Name{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setBusinessName(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Owner Name{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setOwnerName(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Owner Phone{" "}
                                <div className="text-[10px]">
                                  (No Customers Can See This Number)
                                </div>
                              </label>
                              <div className="mt-1">
                                <input
                                  value={ownerPhone}
                                  onChange={(change) =>
                                    handleOwnerPhone(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Owner Email{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setOwnerEmail(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Password{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setPassword(change.target.value)
                                  }
                                  type="password"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Confirm Password{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setConfirmPassword(change.target.value)
                                  }
                                  type="password"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Service Area Zipcode{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setServiceAreaZipcode(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Customer Support Phone{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  value={supportPhone}
                                  onChange={(change) =>
                                    handleSupportPhone(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "88%", marginTop: 12 }}>
                          Pursuant to the regulations set forth by the Telephone
                          Consumer Protection Act (TCPA), we are requesting your
                          consent for text messages. By clicking the sign up
                          button below, you are explicitly acknowledging and
                          affirming your consent for Symbri to send you text
                          messages.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleEvent}
                        id="card-button"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Loading </div>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              arialLabel="loading-indicator"
                            />
                          </div>
                        ) : (
                          "Sign Up"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
