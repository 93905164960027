function SelectStartDate(props) {
  const handleChange = (event) => {
    const date = event.target.value;
    props.setSelectedDate(date);
  };

  return (
    <div className="mt-1 relative block w-full">
      <input
        type="date"
        className="w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 text-left pl-2"
        value={props.selectedDate}
        onChange={handleChange}
        placeholder="Select a date"
      />
    </div>
  );
}

export default SelectStartDate;
