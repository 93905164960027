import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import EmployeeCard from "./EmployeeCard";
import CalendarStrip from "./CalendarStrip";
import { BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";
import { FiAlertOctagon } from "react-icons/fi";
import UnscheduledStopCard from "./UnscheduledStopCard";
import { AiOutlineSearch } from "react-icons/ai";

const SideMenu = (props) => {
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [unscheduledStops, setUnscheduledStops] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stopViewDisplay, setStopViewDisplay] = useState(false);
  const [stopViewData, setStopViewData] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [unscheduledSearchTerm, setUnscheduledSearchTerm] = useState("");
  const [dragId, setDragId] = useState();
  const [allEmployees, setAllEmployees] = useState([]);
  const db = firebase.firestore();

  useEffect(() => {
    setAllEmployees(props.allEmployees);
    console.log(props.allEmployees);
  }, [props.allEmployees]);

  useEffect(() => {
    setUnscheduledStops(props.unscheduledMarkers);
  }, [props.unscheduledMarkers]);

  const handleDeleteEmployee = (value, totalCount) => {
    if (totalCount === 0) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("Employees")
        .doc(value.uid)
        .delete();
    } else {
      alert(
        "Cannot delete an employee with stops! Please remove all stops and try again!"
      );
    }
  };

  const handleSelectDate = (date) => {
    props.setRouteStartDate(dayjs(date).format("MM/DD/YYYY"));
    setSelectedDate(dayjs(date).format("MM/DD/YYYY"));
  };

  const handleGoBackDayView = () => {
    props.setDayViewDisplay(false);
    props.setDayViewData({});
  };

  const handleGoBackStopView = () => {
    setStopViewData({});
    setStopViewDisplay(false);
  };

  const handleCheckedUnscheduledStop = (stop) => {
    let currentSelectedUnscheduledStops = props.selectedUnscheduledStops;

    currentSelectedUnscheduledStops.push(stop);
    props.setSelectedUnscheduledStops(currentSelectedUnscheduledStops);
    props.setRefresh(Math.random(0, 2));
  };

  const handleUnCheckedUnscheduledStop = (stop) => {
    let currentSelectedUnscheduledStops = props.selectedUnscheduledStops;
    const indexOfStop = currentSelectedUnscheduledStops.findIndex(
      (a) => a.stopId === stop.stopId
    );

    currentSelectedUnscheduledStops.splice(indexOfStop, 1);
    props.setSelectedUnscheduledStops(currentSelectedUnscheduledStops);
    props.setRefresh(Math.random(0, 2));
  };

  const handleSaveNewData = (orderArr) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(props.dayViewData.employee.uid)
      .collection("Route")
      .doc(dayjs(selectedDate).format("dddd"))
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const docData = documentSnapshot.data();
          let currentCustomRouteOrders = docData.customRouteOrders;
          let indexOfExisting = currentCustomRouteOrders.findIndex(
            (a) => a.date === dayjs(selectedDate).format("MM/DD/YYYY")
          );

          if (indexOfExisting !== -1) {
            currentCustomRouteOrders.splice(indexOfExisting, 1);
          }
          currentCustomRouteOrders.push({
            date: dayjs(selectedDate).format("MM/DD/YYYY"),
            order: orderArr,
          });

          console.log(currentCustomRouteOrders);

          db.collection("Businesses")
            .doc(selectedBusiness)
            .collection("Workers")
            .doc(props.dayViewData.employee.uid)
            .collection("Route")
            .doc(dayjs(selectedDate).format("dddd"))
            .update({
              customRouteOrders: currentCustomRouteOrders,
              checkDistanceTime: true,
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragIndex = Number(dragId);
    const dropIndex = Number(ev.currentTarget.id);

    console.log(dragIndex);
    console.log(dropIndex);

    // ensure dragIndex and dropIndex are valid numbers
    if (isNaN(dragIndex) || isNaN(dropIndex)) {
      console.error("Invalid drag or drop index");
      return;
    }

    let currentArrOfStops = props.dayViewData.data.sort(
      (a, b) =>
        props.customRouteOrder.indexOf(a.tempStopId ? a.tempStopId : a.stopId) -
        props.customRouteOrder.indexOf(b.tempStopId ? b.tempStopId : b.stopId)
    );

    let value = currentArrOfStops[dragIndex].stopId;
    let currentOrder = [];

    for (let index = 0; index < currentArrOfStops.length; index++) {
      const stop = currentArrOfStops[index];
      console.log(stop.tempStopId ? stop.tempStopId : stop.stopId);
      currentOrder.push(stop.tempStopId ? stop.tempStopId : stop.stopId);
    }

    currentOrder.splice(dragIndex, 1);

    currentOrder.splice(dropIndex, 0, value);
    props.handleUpdateRouteOrder(currentOrder);
    handleSaveNewData(currentOrder);
    setRefresh(Math.random(0, 2));
  };

  return (
    <div>
      <div
        className="hideScroll"
        style={{
          width: 300,
          position: "absolute",
          zIndex: 2000,
          marginLeft: 20,
          marginTop: "10px",
          marginBottom: "10px",
          overflowY: "scroll",
        }}
      >
        <div
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #E5E7EB",
            backgroundColor: "white",
            height: "50vh",
            overflowY: "scroll",
          }}
        >
          <CalendarStrip
            selectedDate={selectedDate}
            handleSelectDate={handleSelectDate}
            disabled={selectedRoute.active}
            serviceLocationsInRoute={props.serviceLocationsInRoute}
          />
          {allEmployees.map((value) => (
            <EmployeeCard
              handleDeleteEmployee={handleDeleteEmployee}
              value={value}
              handleEditMode={props.handleEditMode}
              editModeDay={props.editModeDay}
              selectedBusiness={selectedBusiness}
              selectedRoute={selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              selectedDate={selectedDate}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              key={value.uid}
              handleCancelEditMode={props.handleCancelEditMode}
              handleOpenReschedule={props.handleOpenReschedule}
              rescheduledStops={props.rescheduledStops}
              setDayData={props.setDayData}
              dayData={props.dayData}
              forceEmployeeCardsReload={props.forceEmployeeCardsReload}
              markersDataForProp={props.markersDataForProp}
            />
          ))}
        </div>
        <div
          style={{
            width: 300,
            border: "1px solid #E5E7EB",
            backgroundColor: "white",
            height: 300,
            zIndex: 2000,
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "16%",
            }}
          >
            <div style={{ marginLeft: "96px", fontSize: "0.9rem" }}>
              Unscheduled
            </div>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "20px",
                fontSize: "0.9rem",
              }}
            >
              {unscheduledStops.length}
            </div>
          </div>
          <div
            style={{
              border: "1px solid black",
              width: "88%",
              margin: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              value={unscheduledSearchTerm}
              onChange={(change) =>
                setUnscheduledSearchTerm(change.target.value)
              }
              style={{ width: "100%", outline: "none" }}
            />
            <div style={{ marginRight: "2%", fontSize: "1rem" }}>
              <AiOutlineSearch />
            </div>
          </div>
          <div
            style={{
              height: 200,
              overflowY: "scroll",
            }}
            class="routeBuilderEditSideMenuScroll"
          >
            {unscheduledStops
              .filter((a) =>
                unscheduledSearchTerm.length === 0
                  ? true
                  : a.customerData.name
                      .toLowerCase()
                      .includes(unscheduledSearchTerm.toLowerCase())
              )
              .map((value) => (
                <UnscheduledStopCard
                  setSelectedUnscheduledStop={props.setSelectedUnscheduledStop}
                  selectedUnscheduledStop={props.selectedUnscheduledStop}
                  selectedUnscheduledStops={props.selectedUnscheduledStops}
                  value={value}
                  handleViewDetails={props.handleViewDetails}
                  handleUnCheckedUnscheduledStop={
                    handleUnCheckedUnscheduledStop
                  }
                  handleCheckedUnscheduledStop={handleCheckedUnscheduledStop}
                  handleAddUnscheduledStopToDay={() =>
                    props.handleAddUnscheduledStopToDay(value, allEmployees)
                  }
                />
              ))}
          </div>
        </div>
      </div>

      {props.dayViewDisplay && (
        <div
          style={{
            width: 300,
            position: "absolute",
            zIndex: 2000,
            marginLeft: 20,
            marginTop: "10px",
            marginBottom: "10px",
            overflowY: "scroll",
            backgroundColor: "white",
            height: "50vh",
          }}
          class="routeBuilderEditSideMenuScroll"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#4F46E5",
              height: 44,
              borderBottom: "2px solid gray",
            }}
          >
            <div
              onClick={() => handleGoBackDayView()}
              style={{
                marginLeft: "4%",
                cursor: "pointer",
              }}
            >
              <BsChevronLeft color="white" size={18} />
            </div>

            <div
              style={{ marginLeft: "4%", color: "white", fontSize: "0.88rem" }}
            >
              <div>
                {props.dayViewData.employee.name.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                color: "white",
                flexDirection: "row",
                fontSize: "0.8rem",
                marginRight: "4%",
                justifyContent: "center",
              }}
            >
              <div style={{ marginRight: "4%" }}>
                {dayjs(selectedDate).format("ddd")}
              </div>
              <div>{dayjs(selectedDate).format("MM/DD/YYYY")}</div>
            </div>
          </div>

          <div>
            {props.dayViewData &&
              props.dayViewData.data &&
              props.dayViewData.data
                .sort(
                  (a, b) =>
                    props.customRouteOrder.indexOf(
                      a.tempStopId ? a.tempStopId : a.stopId
                    ) -
                    props.customRouteOrder.indexOf(
                      b.tempStopId ? b.tempStopId : b.stopId
                    )
                )
                .map((value, index) => (
                  <div
                    onDragOver={(ev) => ev.preventDefault()}
                    onDragStart={handleDrag}
                    onDrop={handleDrop}
                    draggable
                    id={index}
                    key={value.tempStopId ? value.tempStopId : value.stopId}
                    stopId={value.tempStopId ? value.tempStopId : value.stopId}
                    style={{
                      minHeight: 60,
                      display: "flex",
                      alignItems: "center",
                      border:
                        props.dayViewData.completedStops.findIndex(
                          (a) => a.stopId === value.stopId
                        ) === -1
                          ? "2px solid gray"
                          : "2px solid green",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "3.6%",
                        fontSize: "0.8rem",
                        width: "8%",
                      }}
                    >
                      {index + 1}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        paddingLeft: "2.8%",
                        fontSize: "0.8rem",
                      }}
                    >
                      <div>
                        {value.customerName
                          ? value.customerName
                          : value.customerData.name.replace(
                              /(^\w{1})|(\s+\w{1})/g,
                              (letter) => letter.toUpperCase()
                            )}
                      </div>
                      <div
                        style={{
                          fontSize: "0.7rem",
                          textAlign: "left",
                        }}
                      >
                        {value.stopNickname || value.name}
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "4%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "0.7rem",
                          textAlign: "right",
                        }}
                      >
                        {value.address.split(",")[0]}
                      </div>
                      <div
                        onClick={() => props.handleViewDetails(value)}
                        style={{
                          fontSize: "0.7rem",
                          marginLeft: "auto",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        View Details
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      )}
      {stopViewDisplay && (
        <div
          style={{
            width: "19%",
            border: "1px solid black",
            backgroundColor: "white",
            height: "60%",
            position: "absolute",
            zIndex: 2000,
            margin: 20,
            marginTop: "0.6%",
            overflowY: "scroll",
            marginLeft: "5%",
          }}
          class="routeBuilderEditSideMenuScroll"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "4%",
            }}
          >
            <div
              onClick={() => handleGoBackStopView()}
              style={{
                marginLeft: "4%",
                cursor: "pointer",
              }}
            >
              <BsChevronLeft size={22} />
            </div>
            <div style={{ marginLeft: "32%" }}>{stopViewData.name}</div>
          </div>
          <div style={{ marginTop: "8%" }}>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>Customer:</div>
              <div>{stopViewData.customerId.substring(0, 28)}...</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>Address:</div>
              <div>{stopViewData.address.substring(0, 28)}...</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>Stop Title:</div>
              <div>{stopViewData.recurringStopType.stopName}</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>Frequency:</div>
              <div>{stopViewData.recurringStopType.recurringSchedule}</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>Service Day:</div> <div>{props.dayViewData.day}</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>Employee:</div> <div>{stopViewData.employeeUid}</div>
            </div>
          </div>
        </div>
      )}

      {props.hasUnactivatedChanges && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            left: "34%",
            top: 72,
            zIndex: 1000,
          }}
        >
          <FiAlertOctagon color="red" size={60} />
          <div style={{ marginLeft: 20, color: "red" }}>
            This route has unactivated changes!
          </div>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
