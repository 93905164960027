import { Fragment, useEffect, useState } from "react";
import AddSubscriptionEmployeeSearch from "./AddSubscriptionEmployeeSearch";
import SelectDay from "./SelectDay";

export default function StopCard(props) {
  const handleSelectEmployee = (value) => {
    props.handleProcessSelection(props.index, "employee", value);
  };

  const handleSelectDay = (value) => {
    props.handleProcessSelection(props.index, "day", value);
  };

  return (
    <div
      style={{
        border: "1px solid black",
        height: 140,
        marginTop: 8,
        borderRadius: 5,
        padding: 8,
      }}
    >
      <div>Stop {props.index + 1}</div>
      <div style={{ marginTop: 8 }}>
        <AddSubscriptionEmployeeSearch
          businessId={props.selectedBusiness}
          setSelectedEmployee={handleSelectEmployee}
          employees={props.allEmployees}
        />
      </div>
      <div style={{ marginTop: 8 }}>
        <SelectDay
          setDay={handleSelectDay}
          businessId={props.selectedBusiness}
        />
      </div>
    </div>
  );
}
