/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { BsPlusLg } from "react-icons/bs";

const publishingOptions = [
  {
    title: "New Employee",
    description: "Add a new employee!",
    current: true,
  },
];

/*

  Removed for now until we decide to add back import employees

  {
    title: "Import Employees",
    description: "Import bulk employees!",
    current: false,
  },



  */

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EmployeesButton(props) {
  const [selected, setSelected] = useState(publishingOptions[0]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only"></Listbox.Label>
          <div className="relative">
            <div className="inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
              <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => props.handleButtonClick(selected.title)}
                  className="relative inline-flex items-center bg-indigo-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white"
                >
                  <BsPlusLg />
                  <p className="ml-2.5 text-sm font-medium">{selected.title}</p>
                </div>
                <Listbox.Button className="relative inline-flex items-center bg-indigo-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"></Listbox.Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Listbox>
  );
}
