import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";

const ImportEmployeeCard = (props) => {
  const [selectedCsvFields, setSelectedCsvFields] = useState([]);
  const FieldName = props.value;

  // make sure swap out customer phone and email so we dont email and text actual customers.

  const handleSelect = (data) => {
    let currentSelectedFields = selectedCsvFields;
    currentSelectedFields.push(data);
    setSelectedCsvFields(currentSelectedFields);
    let currentAssignedFields = props.assignedFields;
    const indexOfAssignedField = currentAssignedFields.findIndex(
      (a) => a.mainField === FieldName
    );
    console.log(indexOfAssignedField);
    if (indexOfAssignedField === -1) {
      currentAssignedFields.push({
        mainField: FieldName,
        assignedFields: currentSelectedFields[0],
      });
      props.setAssignedFields(currentAssignedFields);
      console.log(currentAssignedFields);
    } else {
      currentAssignedFields.splice(indexOfAssignedField, 1);
      currentAssignedFields.push({
        mainField: FieldName,
        assignedFields: currentSelectedFields[0],
      });
      props.setAssignedFields(currentAssignedFields);
      console.log(currentAssignedFields);
    }
  };

  const handleDeselect = (data) => {
    const currentSelectedFields = selectedCsvFields;
    currentSelectedFields.splice(
      currentSelectedFields.findIndex((a) => a.id === data.id),
      1
    );
    setSelectedCsvFields(currentSelectedFields);
  };

  return (
    <div
      style={{
        minHeight: 80,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          marginLeft: "2%",
          width: 100,
          height: 36,
          display: "flex",
          alignItems: "center",
          fontSize: "0.8rem",
        }}
      >
        {FieldName}
      </div>
      <div style={{ width: "72%", marginLeft: "2%" }}>
        <Multiselect
          options={props.csvFields} // Options to display in the dropdown
          selectedValues={selectedCsvFields} // Preselected value to persist in dropdown
          onSelect={handleSelect} // Function will trigger on select event
          onRemove={handleDeselect} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />
      </div>
    </div>
  );
};

export default ImportEmployeeCard;
