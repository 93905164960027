import { Editor } from "react-draft-wysiwyg";
import firebase from "firebase/compat/app";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState } from "draft-js";
import xss from "xss";
import "../../node_modules/video-react/dist/video-react.css";
import { useState, useRef, useEffect } from "react";
import { generateBusinessId } from "../Tools/generateBusinessId";
import { IoSendSharp, IoCreate, IoMan, IoHappy } from "react-icons/io5";
import { AiOutlineCheck } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";
import EmojiPicker from "emoji-picker-react";
import NewMessage from "./NewMessage";
import dayjs from "dayjs";
import placeholder from "../Assets/placeholder.jpg";
import { stripHtml } from "string-strip-html";
import { Rings } from "react-loader-spinner";
import { getDefaultKeyBinding, KeyBindingUtil, RichUtils } from "draft-js";
import MessageCard from "./MessageCard";

const { hasCommandModifier } = KeyBindingUtil;

export default function CompanyMessages(props) {
  const [sendMessageContent, setSendMessageContent] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const db = firebase.firestore();
  const workerMessages = useRef();
  const [readCount, setReadCount] = useState(0);
  const [doneCount, setDoneCount] = useState(0);
  const [messageDisplay, setMessageDisplay] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMessage, setSelectedMessage] = useState();
  const [newMessageDisplay, setNewMessageDisplay] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allWorkers, setAllWorkers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("open");
  const [emojiSelect, setEmojiSelect] = useState(false);
  const [currentUsersName, setCurrentUsersName] = useState("");
  const [allThreads, setAllThreads] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [sendingMessageLoader, setSendingMessageLoader] = useState(false);
  const currentListener = useRef();

  useEffect(() => {
    const currentUserId = firebase.auth().currentUser?.uid;
    const cleanupListeners = [];

    const unsubscribeUser = db
      .collection("Users")
      .doc(currentUserId)
      .onSnapshot(async (documentSnapshot) => {
        const data = documentSnapshot.data();
        setCurrentUsersName(data.name.trim());
        console.log(currentUserId);
        // Unsubscribe and remove previous listeners
        cleanupListeners.forEach((listener) => listener());
        cleanupListeners.length = 0;

        const threadIds = data.threadIds;
        if (threadIds) {
          const threadPromises = threadIds.map((threadId) =>
            db.collection("Messages").doc(threadId).get()
          );

          const threadDocs = await Promise.all(threadPromises);
          const threads = threadDocs.map((doc) => doc.data());

          const doneThreads = threads.filter(
            (thread) =>
              thread.members.find((member) => member.uid === currentUserId)
                .threadStatus === "done"
          );

          const readThreads = threads.filter(
            (thread) =>
              thread.members.find((member) => member.uid === currentUserId)
                .threadStatus === "open"
          );

          setAllThreads(threads);
          setDoneCount(doneThreads.length);
          setReadCount(readThreads.length);

          threadIds.forEach((threadId, index) => {
            const listener = db
              .collection("Messages")
              .doc(threadId)
              .onSnapshot(
                (querySnapshot) => {
                  const updatedThread = querySnapshot.data();
                  setAllThreads((prevThreads) => {
                    const updatedThreads = prevThreads.slice();
                    updatedThreads[index] = updatedThread;
                    return updatedThreads;
                  });
                },
                (e) => {
                  console.log(threadId);
                  console.log(e);
                }
              );

            cleanupListeners.push(listener);
          });
        }
      });

    return () => {
      // Unsubscribe all listeners
      cleanupListeners.forEach((listener) => listener());
      unsubscribeUser();
    };
  }, []);

  const handleAddEmoji = (emoji) => {
    let currentHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ).toString();

    let newHtml = currentHTML.trim() + emoji.emoji.trim();
    const contentBlock = htmlToDraft(newHtml);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const newEditorState = EditorState.createWithContent(contentState);

    setEditorState(newEditorState);

    setSendMessageContent(
      draftToHtml(convertToRaw(newEditorState.getCurrentContent())).toString()
    );
    setEmojiSelect(!emojiSelect);
  };

  const handleSelectMessageThread = (value) => {
    setSelectedMessage(value);
    if (currentListener.current) {
      currentListener.current();
    }

    // listen for threadId updates

    handleThreadRead(value);

    const listener = db
      .collection("Messages")
      .doc(value.threadId)
      .collection("Messages")
      .onSnapshot((snapshot) => {
        let messages = [];
        let promises = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const p = new Promise((resolve) => {
            let doc = snapshot.docs[index].data();

            if (doc.userType === "employee") {
              db.collection("Businesses")
                .doc(props.selectedBusiness)
                .collection("Workers")
                .doc(doc.uid)
                .get()
                .then((documentSnapshot) => {
                  const employeeData = documentSnapshot.data();

                  doc.name = employeeData.name;
                  doc.profilePhoto = employeeData.profilePhoto;
                  messages.push(doc);
                  resolve("done");
                });
            } else {
              db.collection("Users")
                .doc(doc.uid)
                .get()
                .then((documentSnapshot) => {
                  const userData = documentSnapshot.data();
                  doc.name = userData.firstName + " " + userData.lastName;
                  messages.push(doc);
                  resolve("done");
                });
            }
          });
          promises.push(p);
        }
        Promise.all(promises).then(() => {
          setCurrentMessages(messages);
          setMessageDisplay(true);
          var objDiv = document.getElementById("viewMessageMainScrollDiv");
          objDiv.scrollTop = objDiv.scrollHeight;
        });
      });

    currentListener.current = listener;
  };

  const handleSendMessage = () => {
    const newMessageId = generateBusinessId();
    setSendingMessageLoader(true);
    db.collection("Messages")
      .doc(selectedMessage.threadId)
      .update({ lastMessageDate: new Date() })
      .then(() => {
        db.collection("Messages")
          .doc(selectedMessage.threadId)
          .collection("Messages")
          .doc(newMessageId)
          .set({
            messageId: newMessageId,
            body: sendMessageContent,
            uid: firebase.auth().currentUser.uid,
            threadId: selectedMessage.threadId,
            displayDate: dayjs().format("MM/DD h:mm A"),
            date: new Date(),
            userType: "employee",
          })
          .then(() => {
            setEditorState(EditorState.createEmpty());
            setSendMessageContent("");
            var objDiv = document.getElementById("viewMessageMainScrollDiv");
            objDiv.scrollTop = objDiv.scrollHeight;
            firebase
              .auth()
              .currentUser.getIdToken()
              .then(async (token) => {
                let currentMembers = selectedMessage.members;

                let otherMember =
                  currentMembers[
                    currentMembers.findIndex(
                      (x) => x.uid !== firebase.auth().currentUser.uid
                    )
                  ];

                if (otherMember.uid.length > 0) {
                  db.collection("Users")
                    .doc(otherMember.uid)
                    .get()
                    .then(async (userData) => {
                      const userDataDoc = userData.data();
                      setSendingMessageLoader(false);
                      handleThreadUnread(selectedMessage);

                      await fetch(
                        "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                        {
                          method: "POST",
                          body: JSON.stringify({
                            token: token,
                            skipInApp: true,
                            notificationData: {
                              hasApp: {
                                body: stripHtml(sendMessageContent).result,
                                title: `New message from ${otherMember.name}!`,
                              },
                              noApp: {
                                body:
                                  stripHtml(sendMessageContent).result +
                                  ". Download the app to be able to respond!",
                                title: `New message from ${otherMember.name}!`,
                              },
                            },
                            customerId: userDataDoc.customerId,
                          }),
                        }
                      );
                    });
                } else {
                  setSendingMessageLoader(false);
                  handleThreadUnread(selectedMessage);
                  console.log(otherMember.customerId);

                  await fetch(
                    "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        token: token,
                        skipInApp: true,
                        notificationData: {
                          hasApp: {
                            body: stripHtml(sendMessageContent).result,
                            title: `New message from ${otherMember.name}!`,
                          },
                          noApp: {
                            body:
                              stripHtml(sendMessageContent).result +
                              " Download the app to be able to respond! \n \n https://links.symbri.com/customer ",
                            title: `New message from ${otherMember.name}!`,
                          },
                        },
                        customerId: otherMember.customerId,
                      }),
                    }
                  );
                }
              });
          });
      });
  };

  const handleMarkThreadDone = (thread, event) => {
    if (event) {
      event.stopPropagation();
    }
    let currentMembers = thread.members;

    currentMembers[
      currentMembers.findIndex((x) => x.uid === firebase.auth().currentUser.uid)
    ].threadStatus = "done";

    db.collection("Messages")
      .doc(thread.threadId)
      .update({ members: currentMembers });

    let currentThreads = allThreads;
    currentThreads[
      currentThreads.findIndex((a) => a.threadId === thread.threadId)
    ].members[
      currentThreads[
        currentThreads.findIndex((a) => a.threadId === thread.threadId)
      ].members.findIndex((a) => a.uid === firebase.auth().currentUser.uid)
    ].threadStatus = "done";
    setAllThreads(currentThreads);
    setDoneCount(
      currentThreads.filter(
        (a) =>
          a.members[
            a.members.findIndex(
              (a) => a.uid === firebase.auth().currentUser.uid
            )
          ].threadStatus === "done"
      ).length
    );

    setReadCount(
      currentThreads.filter(
        (a) =>
          a.members[
            a.members.findIndex(
              (a) => a.uid === firebase.auth().currentUser.uid
            )
          ].threadStatus === "open"
      ).length
    );
  };

  const handleMarkThreadOpen = (thread, event) => {
    if (event) {
      event.stopPropagation();
    }
    let currentMembers = thread.members;

    currentMembers[
      currentMembers.findIndex((x) => x.uid === firebase.auth().currentUser.uid)
    ].threadStatus = "open";

    db.collection("Messages")
      .doc(thread.threadId)
      .update({ members: currentMembers });

    let currentThreads = allThreads;
    currentThreads[
      currentThreads.findIndex((a) => a.threadId === thread.threadId)
    ].members[
      currentThreads[
        currentThreads.findIndex((a) => a.threadId === thread.threadId)
      ].members.findIndex((a) => a.uid === firebase.auth().currentUser.uid)
    ].threadStatus = "open";
    setAllThreads(currentThreads);
    setDoneCount(
      allThreads.filter(
        (a) =>
          a.members[
            a.members.findIndex(
              (a) => a.uid === firebase.auth().currentUser.uid
            )
          ].threadStatus === "done"
      ).length
    );

    setReadCount(
      allThreads.filter(
        (a) =>
          a.members[
            a.members.findIndex(
              (a) => a.uid === firebase.auth().currentUser.uid
            )
          ].threadStatus === "open"
      ).length
    );
  };

  const addNewThreadToAllThreads = (newThread) => {
    setNewMessageDisplay(false);
    let currentThreads = allThreads;
    currentThreads.push(newThread);
    setAllThreads(currentThreads);
    setDoneCount(
      currentThreads.filter(
        (a) =>
          a.members[
            a.members.findIndex(
              (a) => a.uid === firebase.auth().currentUser.uid
            )
          ].threadStatus === "done"
      ).length
    );

    setReadCount(
      currentThreads.filter(
        (a) =>
          a.members[
            a.members.findIndex(
              (a) => a.uid === firebase.auth().currentUser.uid
            )
          ].threadStatus === "open"
      ).length
    );

    handleSelectMessageThread(newThread);
  };

  const handleThreadUnread = (thread) => {
    let currentMembers = thread.members;

    currentMembers[
      currentMembers.findIndex((x) => x.uid !== firebase.auth().currentUser.uid)
    ].unread = true;

    db.collection("Messages")
      .doc(thread.threadId)
      .update({ members: currentMembers });
  };

  const handleThreadRead = (thread) => {
    let currentMembers = thread.members;

    currentMembers[
      currentMembers.findIndex((x) => x.uid === firebase.auth().currentUser.uid)
    ].unread = false;

    db.collection("Messages")
      .doc(thread.threadId)
      .update({ members: currentMembers });

    let currentThreads = allThreads;
    currentThreads[
      currentThreads.findIndex((a) => a.threadId === thread.threadId)
    ].members[
      currentThreads[
        currentThreads.findIndex((a) => a.threadId === thread.threadId)
      ].members.findIndex((a) => a.uid === firebase.auth().currentUser.uid)
    ].unread = false;
    setAllThreads(currentThreads);
  };

  const handleKeyCommand = (command, editorState) => {
    if (command === "send-message") {
      handleSendMessage();
      return "handled";
    }
    return "not-handled";
  };

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 13 && !hasCommandModifier(e)) {
      // Detect Enter key without modifiers
      return "send-message"; // Custom command
    }
    return getDefaultKeyBinding(e);
  };

  return (
    <div class="container min-w-full">
      <div class="min-w-full lg:grid lg:grid-cols-3">
        <div class="border-r border-gray-300 lg:col-span-1 min-h-[92vh]">
          <div class="mx-3 my-3">
            <div class="relative text-gray-600">
              <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  class="w-6 h-6 text-gray-300"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </span>
              <input
                onChange={(change) => setSearchTerm(change.target.value)}
                type="search"
                class="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
                name="search"
                placeholder="Search"
                required
              />
            </div>
          </div>

          <ul class="overflow-auto">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                height: 40,
                alignItems: "center",
                borderBottom: "1px solid #D1D5DB",
              }}
            >
              <div
                onClick={() => setSelectedStatus("open")}
                style={{
                  color: selectedStatus === "open" ? "#796DFE" : "#6B7280",
                  width: 60,
                  marginLeft: 8,
                  height: 24,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
              >
                Open {readCount}
              </div>
              <div
                onClick={() => setSelectedStatus("done")}
                style={{
                  color: selectedStatus === "done" ? "#796DFE" : "#6B7280",
                  width: 60,
                  height: 24,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
              >
                Done {doneCount}
              </div>
              <div
                onClick={() => setNewMessageDisplay(true)}
                style={{
                  color: "#6B7280",
                  width: 60,
                  height: 24,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                  marginLeft: "auto",
                }}
              >
                <IoCreate size={26} color="#4F46E5" />
              </div>
            </div>

            <li>
              {allThreads
                .filter(
                  (a) =>
                    a.members[
                      a.members.findIndex(
                        (a) => a.uid === firebase.auth().currentUser.uid
                      )
                    ].threadStatus === selectedStatus
                )
                .filter((a) =>
                  searchTerm.length > 0
                    ? a.members.some((a) =>
                        a.name.toLowerCase().includes(searchTerm.toLowerCase())
                      )
                    : true
                )
                .sort((a, b) => {
                  let aSeconds =
                    a.lastMessageDate && a.lastMessageDate.seconds
                      ? a.lastMessageDate.seconds
                      : 0;
                  let bSeconds =
                    b.lastMessageDate && b.lastMessageDate.seconds
                      ? b.lastMessageDate.seconds
                      : 0;

                  return bSeconds - aSeconds;
                })
                .map((value) => (
                  <MessageCard
                    value={value}
                    selectedMessage={selectedMessage}
                    handleSelectMessageThread={handleSelectMessageThread}
                    handleMarkThreadOpen={handleMarkThreadOpen}
                    handleMarkThreadDone={handleMarkThreadDone}
                  />
                ))}
            </li>
          </ul>
        </div>
        <div class="hidden lg:col-span-2 lg:block">
          <div class="w-full">
            {selectedMessage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "sticky",
                  width: "auto",
                  margin: 10,
                  top: 26,
                  backgroundColor: "#F3F4F6",
                  height: 60,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 20,
                  }}
                >
                  {selectedMessage.members
                    .filter((a) => a.uid !== firebase.auth().currentUser.uid)
                    .map((member) => (
                      <div
                        style={{
                          margin: 8,
                          textAlign: "left",
                          color: "#6B7280",
                        }}
                      >
                        {member.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                          letter.toUpperCase()
                        )}
                      </div>
                    ))}
                </div>
                <div
                  onClick={() => handleMarkThreadDone(selectedMessage)}
                  style={{
                    marginLeft: "auto",
                    marginRight: 20,
                    cursor: "pointer",
                    display:
                      selectedMessage.members[
                        selectedMessage.members.findIndex(
                          (x) => x.uid === firebase.auth().currentUser.uid
                        )
                      ].threadStatus === "open"
                        ? "flex"
                        : "none",
                  }}
                >
                  <AiOutlineCheck color="#6B7280" size={24} />
                </div>
                <div
                  onClick={() => handleMarkThreadOpen(selectedMessage)}
                  style={{
                    marginLeft: "auto",
                    marginRight: 20,
                    cursor: "pointer",
                    display:
                      selectedMessage.members[
                        selectedMessage.members.findIndex(
                          (x) => x.uid === firebase.auth().currentUser.uid
                        )
                      ].threadStatus === "done"
                        ? "flex"
                        : "none",
                  }}
                >
                  <RiArrowGoBackFill color="#6B7280" size={24} />
                </div>
              </div>
            )}
            <div class="relative w-full overflow-y-auto">
              <div
                id="viewMessageMainScrollDiv"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "scroll",
                  height: "64vh",
                  paddingBottom: 20,
                }}
              >
                {currentMessages
                  .sort((a, b) => a.date - b.date)
                  .map((value, index) => (
                    <div
                      style={{
                        width: "100%",
                      }}
                      key={value.messageId}
                    >
                      <div
                        style={{
                          borderRadius: 5,
                          height: "auto",
                          minHeight: 60,
                          maxWidth: "64vw",
                          padding: 10,
                          margin: 10,
                          marginLeft: "3%",
                          display: "flex",
                          overflowWrap: "anywhere",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <div className="relative">
                              <img
                                className="h-12 w-12 rounded-full"
                                src={value.profilePhoto || placeholder}
                                alt=""
                              />
                              <span
                                className="absolute inset-0 shadow-inner rounded-full"
                                aria-hidden="true"
                              />
                            </div>
                          </div>

                          <div className="flex flex-col ml-4">
                            <div className="flex flex-row items-center">
                              <div
                                style={{
                                  color: "black",
                                  display: "flex",
                                  fontSize: "1rem",
                                }}
                              >
                                {value.name.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  display: "flex",
                                  fontSize: "0.8rem",
                                  marginLeft: 6,
                                  marginTop: 2,
                                }}
                              >
                                - {value.displayDate}
                              </div>
                            </div>
                            <div
                              class="htmlRenderMessage"
                              dangerouslySetInnerHTML={{
                                __html: xss(value.body),
                              }}
                              style={{
                                textAlign: "left",
                                color: "black",
                                fontSize: "0.8rem",
                                marginTop: 4,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div
              style={{
                display: selectedMessage ? "flex" : "none",
                flexDirection: "column",
                width: "auto",
              }}
            >
              {emojiSelect && (
                <div style={{ marginTop: -440 }}>
                  <EmojiPicker onEmojiClick={handleAddEmoji} />
                </div>
              )}
              <div
                style={{
                  width: "98%",
                  alignSelf: "center",
                }}
              >
                <Editor
                  keyBindingFn={myKeyBindingFn}
                  handleKeyCommand={handleKeyCommand}
                  toolbarCustomButtons={[
                    <div
                      style={{
                        marginLeft: "1.2%",
                        marginRight: "1.2%",
                        cursor: "pointer",
                      }}
                    >
                      <IoHappy
                        onClick={() => setEmojiSelect(!emojiSelect)}
                        size={20}
                      />
                    </div>,
                    <div
                      onClick={handleSendMessage}
                      style={{
                        marginLeft: "auto",
                        marginRight: "1.2%",
                        cursor: "pointer",
                      }}
                    >
                      {sendingMessageLoader ? (
                        <Rings
                          height={22}
                          width={22}
                          color="#4F46E5"
                          arialLabel="loading-indicator"
                        />
                      ) : (
                        <IoSendSharp size={20} color="#4F46E5" />
                      )}
                    </div>,
                  ]}
                  toolbar={{
                    options: [],
                    inline: {
                      options: [],
                    },
                  }}
                  editorState={editorState}
                  onEditorStateChange={(change) => {
                    setEditorState(change);
                    setSendMessageContent(
                      xss(
                        draftToHtml(
                          convertToRaw(change.getCurrentContent())
                        ).toString()
                      )
                    );
                  }}
                  placeholder="Compose new message..."
                  editorStyle={{
                    backgroundColor: "white",
                    height: "14vh",
                    paddingLeft: 12,
                    overflowY: "auto",
                    borderBottom: "1px solid #D1D5DB",
                    borderRight: "1px solid #D1D5DB",
                    borderLeft: "1px solid #D1D5DB",
                    borderTop: "1px solid #D1D5DB",
                    borderRadius: 5,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                  toolbarStyle={{
                    marginBottom: 0,
                    borderTop: "1px solid #D1D5DB",
                    borderLeft: "1px solid #D1D5DB",
                    borderRight: "1px solid #D1D5DB",
                    borderRightRadius: 5,
                    borderLeftRadius: 5,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewMessage
        allCustomers={allCustomers}
        open={newMessageDisplay}
        setOpen={setNewMessageDisplay}
        businessId={props.selectedBusiness}
        allWorkers={allWorkers}
        workerMessages={workerMessages.current}
        allThreads={allThreads}
        addNewThreadToAllThreads={addNewThreadToAllThreads}
      />
    </div>
  );
}
