import React from "react";
import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

const SubCard = ({
  subscription,
  handleViewSubscriptionDetails,
  handleViewSubscription,
  selectedBusiness,
}) => {
  const db = firebase.firestore();
  const [enrolled, setEnrolled] = useState(0);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const querySnapshot = await db
          .collection("Customers")
          .where("businessIds", "array-contains", selectedBusiness)
          .get();

        let customersArray = [];

        for (const doc of querySnapshot.docs) {
          const customerDoc = doc.data();

          const serviceLocationSnapshot = await db
            .collection("Customers")
            .doc(customerDoc.customerId)
            .collection("ServiceLocations")
            .where("businessId", "==", selectedBusiness)
            .get();

          for (const serviceDoc of serviceLocationSnapshot.docs) {
            const serviceLocationDoc = serviceDoc.data();

            if (
              serviceLocationDoc.subscriptions.subscriptionId ===
              subscription.subscriptionId
            ) {
              customersArray.push({
                serviceLocationId: serviceLocationDoc.serviceLocationId,
                serviceLocationOwnerName: `${customerDoc.customerFirstName} ${customerDoc.customerLastName}`,
                serviceLocationAddress: serviceLocationDoc.address,
                serviceLocationName: serviceLocationDoc.name,
                subscription: serviceLocationDoc.subscriptions,
              });
            }
          }
        }

        setEnrolled(customersArray.length);
      } catch (error) {
        console.error("Error fetching customers: ", error);
      }
    };

    fetchCustomers();
  }, []);

  return (
    <tr key={subscription.subscriptionTitle}>
      <td
        className="whitespace-nowrap overflow-hidden text-ellipsis py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
        style={{ maxWidth: "250px" }}
      >
        {subscription.subscriptionTitle.replace(
          /(^\w{1})|(\s+\w{1})/g,
          (letter) => letter.toUpperCase()
        )}
      </td>
      <td
        className="whitespace-nowrap overflow-hidden text-ellipsis px-3 py-4 text-sm text-gray-500"
        style={{ maxWidth: "250px" }}
      >
        {subscription.subscriptionDescription}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        ${subscription.subscriptionPrice}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {subscription.subscriptionCode.toString()}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {enrolled}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 flex">
        <div
          onClick={() => handleViewSubscriptionDetails(subscription)}
          className="text-indigo-600 hover:text-indigo-900 pr-4"
          style={{ marginLeft: "auto" }}
        >
          View
        </div>
        <div
          onClick={() => handleViewSubscription(subscription)}
          className="text-indigo-600 hover:text-indigo-900"
        >
          Edit
        </div>
      </td>
    </tr>
  );
};

export default SubCard;
