import { useEffect, useState } from "react";
import EditJob from "../Customers/ViewJob";
import { IoLocationSharp } from "react-icons/io5";
import firebase from "firebase/compat/app";

export default function ServiceLocationStops(props) {
  const [selectedJob, setSelectedJob] = useState();
  const [editJobDisplay, setEditJobDisplay] = useState(false);
  const [routes, setRoutes] = useState([]);
  const db = firebase.firestore();

  useEffect(() => {
    db.collection("Customers")
      .doc(props.serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(props.serviceLocation.serviceLocationId)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        console.log(data);
        setRoutes(data.routes);
      });
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border-gray-200 border-2">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Stops
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white customer-profile-tbody">
                  {routes
                    .filter((a) => !a.temp)
                    .map((stop) => (
                      <tr key={stop.stopId}>
                        {stop.stopNickname && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  <IoLocationSharp
                                    color="#4F46E5"
                                    className="h-10 w-10 rounded-full"
                                  />
                                </div>
                                <div
                                  className="ml-4"
                                  style={{ maxWidth: 120, minWidth: 120 }}
                                >
                                  <div className="font-medium text-gray-900">
                                    {stop.stopNickname.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )}
                                  </div>
                                  <div className="font-medium text-gray-500">
                                    {stop.type.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{ maxWidth: 120, minWidth: 120 }}
                              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ml-[8%]"
                            >
                              <div className="text-gray-900 text-left">
                                {stop.contractor.name.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </div>
                            </td>
                            <td
                              style={{ maxWidth: 120, minWidth: 120 }}
                              className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 ml-[8%]"
                            >
                              <div className="text-gray-900 text-left">
                                {stop.dayOfTheWeek.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </div>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 ml-[8%]">
                              <div className="text-gray-900 text-left">
                                {stop.frequency.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </div>
                            </td>
                          </div>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <EditJob
        selectedJob={selectedJob}
        selectedBusiness={props.businessId}
        setOpen={setEditJobDisplay}
        open={editJobDisplay}
      />
    </div>
  );
}
