/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import CheckBox from "../../GlobalComponents/CheckBox";
import { Rings } from "react-loader-spinner";
import dayjs from "dayjs";
import { generateBusinessId } from "../../Tools/generateBusinessId";
import { toast } from "react-toastify";
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

const ActivateRouteMenu = (props) => {
  const db = firebase.firestore();
  const [currentStopTypes, setCurrentStopTypes] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [notificationBody, setNotificationBody] = useState();
  const [notificiationDate, setNotificationDate] = useState(new Date());
  const [sendNotifications, setSendNotifications] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCustomerIds, setAllCustomerIds] = useState([]);
  const selectedBusiness = props.selectedBusiness;
  const selectedRoute = props.selectedRoute;

  useEffect(() => {
    let stops = [];
    let customers = [];
    props.serviceLocationsInRoute.forEach((value) => {
      customers.push(value.customerId);
      if (!stops.includes(value.recurringStopType.stopName)) {
        stops.push(value.recurringStopType.stopName);
      }
      setCurrentStopTypes(stops);
    });
    setAllCustomerIds(customers);
    const defaultNotificationBody =
      "Hi {customerName}, your day for {recurringStop} has changed. Starting on {mapStartDate}, your new day for {recurringStop} will be on {weekday}'s and your new cleaner will be {cleanerName}. We appreciate your business!";

    setNotificationBody(defaultNotificationBody);
  }, []);

  const handleActivateRoute = async () => {
    setLoading(true);

    if (sendNotifications) {
      // need to loop through every service location in route and get the owners notification token data

      for (let index = 0; index < allCustomerIds.length; index++) {
        const customerId = allCustomerIds[index];
        const scheduledNotificationId = generateBusinessId();

        db.collection("Users")
          .where("customerId", "==", customerId)
          .get()
          .then((querySnapshot) => {
            for (let index = 0; index < querySnapshot.docs.length; index++) {
              const userData = querySnapshot.docs[index].data();

              if (userData.notificationToken) {
                db.collection("ScheduledNotifications")
                  .doc(scheduledNotificationId)
                  .set({
                    notificationBody: notificationBody,
                    notificiationDate: notificiationDate,
                    notificationId: scheduledNotificationId,
                    notificationTitle: "",
                    notificationToken: userData.notificationToken,
                  });
              }
            }
          });
      }
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        const today = dayjs().format("MM/DD/YYYY");
        console.log(today);
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/handleActivateRoute",
          {
            method: "POST",
            body: JSON.stringify({
              selectedBusiness: selectedBusiness,
              selectedRoute: selectedRoute,
              serviceLocationsInRoute: props.serviceLocationsInRoute,
              routeStartDate: props.routeStartDate,
              sendNotifications: sendNotifications,
              token: token,
              today: today,
            }),
          }
        )
          .then((json) => json.json())
          .then((res) => {
            setLoading(false);
            props.setActivateRouteDisplay(false);
            props.handleCloseRouteClick();
          })
          .catch((e) => {
            console.log(e);
          });
      });
  };

  return (
    <div>
      {currentStep === 0 && (
        <div
          style={{
            width: "28%",
            border: "1px solid black",
            backgroundColor: "white",
            height: "26%",
            position: "fixed",
            zIndex: 1000,
            margin: 20,
            right: "26%",
            marginTop: "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "4%", width: "92%" }}>
            Alert! Once map is activated all scheduled stops that match the
            service types below will be deleted and replaced.
          </div>
          <div style={{ display: "flex", marginTop: "4%" }}>
            {currentStopTypes.map((value, index) => (
              <div key={index} style={{ margin: 2 }}>
                {value}
                {index !== currentStopTypes.length - 1 ? "," : ""}
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              position: "absolute",
              bottom: 0,
              marginBottom: "2%",
            }}
          >
            <div
              onClick={() => props.setActivateRouteDisplay(false)}
              style={{
                height: 30,
                border: "1px solid black",
                width: "24%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FF2600",
                color: "white",
                cursor: "pointer",
                margin: 4,
              }}
            >
              Cancel
            </div>
            <div
              onClick={() => setCurrentStep(1)}
              style={{
                height: 30,
                border: "1px solid black",
                width: "24%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#00A6FF",
                color: "white",
                cursor: "pointer",
                margin: 4,
                marginBottom: "2%",
                marginRight: "2%",
              }}
            >
              Next
            </div>
          </div>
        </div>
      )}
      {currentStep === 1 && (
        <div
          style={{
            width: "28%",
            border: "1px solid black",
            backgroundColor: "white",
            height: "46%",
            position: "fixed",
            zIndex: 1000,
            margin: 20,
            right: "26%",
            marginTop: "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "4%", width: "92%" }}>
            Customer Notifications
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "4%",
              width: "100%",
              justifyContent: "center",
              height: "20%",
            }}
          >
            <textarea
              onChange={(change) => setNotificationBody(change.target.value)}
              value={notificationBody}
              style={{ width: "92%" }}
            />
          </div>
          <div style={{ marginTop: "4%", width: "92%" }}>
            Send notification on
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "4%",
              width: "100%",
              justifyContent: "center",
              height: "12%",
            }}
          >
            <input
              onChange={(change) => setNotificationDate(change.target.value)}
              value={notificiationDate}
              type="date"
              style={{ width: "92%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "6%",
              alignSelf: "flex-end",
              marginRight: "4%",
            }}
          >
            <div style={{ marginTop: 1, paddingRight: 4 }}>
              Send notifications
            </div>
            <CheckBox
              onChange={() => setSendNotifications(!sendNotifications)}
              checked={sendNotifications}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              position: "absolute",
              bottom: 0,
              marginBottom: "2%",
            }}
          >
            <div
              onClick={() => setCurrentStep(0)}
              style={{
                height: 30,
                border: "1px solid black",
                width: "24%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FF2600",
                color: "white",
                cursor: "pointer",
                margin: 4,
                marginBottom: "2%",
                marginRight: "2%",
              }}
            >
              Back
            </div>
            <div
              onClick={() => handleActivateRoute()}
              style={{
                height: 30,
                border: "1px solid black",
                width: "24%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#00A6FF",
                color: "white",
                cursor: "pointer",
                margin: 4,
                marginBottom: "2%",
                marginRight: "2%",
              }}
            >
              {loading ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>Loading </div>
                  <Rings
                    height={30}
                    width={30}
                    color="white"
                    arialLabel="loading-indicator"
                  />
                </div>
              ) : (
                "Activate"
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivateRouteMenu;
