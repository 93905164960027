import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const CalendarStrip = (props) => {
  const [currentDisplayNumber, setCurrentDisplayNumber] = useState(7);
  const [allDates, setAllDates] = useState([]);

  useEffect(() => {
    const datesArr = generateDates(new Date());
    setAllDates(datesArr);
  }, [props.selectedDate]);

  const generateDates = (date) => {
    const twoMonthsBeforeDate = dayjs(date).day(1).subtract(2, "days");
    const twoMonthsBAfterDate = dayjs(date).add(2, "month");
    let arr = [];
    for (
      let dt = new Date(twoMonthsBeforeDate);
      dt <= twoMonthsBAfterDate;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const handleSelectDate = (date) => {
    props.handleSelectDate(date);
  };

  const handleRightClick = () => {
    if (currentDisplayNumber + 7 < allDates.length) {
      setCurrentDisplayNumber(currentDisplayNumber + 7);
    } else {
      console.log("This is the end!");
    }
  };

  const handleLeftClick = () => {
    if (currentDisplayNumber - 7 >= 7) {
      setCurrentDisplayNumber(currentDisplayNumber - 7);
    } else {
      console.log("This is the beginning!");
    }
  };

  return (
    <div id="calendarStripRouteBuilderMainDiv">
      <div onClick={() => handleLeftClick()} style={{ cursor: "pointer" }}>
        <BsChevronLeft size={15} />
      </div>
      <div style={{ display: "flex" }}>
        {allDates
          .slice(currentDisplayNumber - 7, currentDisplayNumber)
          .map((value, index) => (
            <div
              key={dayjs(value).format("MM-DD-YYYY")}
              onClick={() => handleSelectDate(value)}
              id={dayjs(value).format("MM-DD-YYYY")}
              style={{
                border: "#000 1px solid",
                backgroundColor:
                  dayjs(props.selectedDate).format("MM-DD-YYYY") ===
                  dayjs(value).format("MM-DD-YYYY")
                    ? "#796DFE"
                    : "white",
                margin: 4,
                marginLeft: 2.4,
                marginRight: 2.4,
                display: "flex",
                minWidth: "30px",
                height: "30px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "column",
                color:
                  dayjs(props.selectedDate).format("MM-DD-YYYY") ===
                  dayjs(value).format("MM-DD-YYYY")
                    ? "white"
                    : "black",
                fontSize: "0.6rem",
              }}
            >
              <div>{dayjs(value).format("DD")}</div>
              <div>{dayjs(value).format("ddd")}</div>
            </div>
          ))}
      </div>
      <div onClick={() => handleRightClick()} style={{ cursor: "pointer" }}>
        <BsChevronRight size={15} />
      </div>
    </div>
  );
};

export default CalendarStrip;
