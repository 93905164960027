import React, { useState } from "react";
import { CSVReader } from "react-papaparse";
import ImportCustomerCard from "./ImportCustomerCard";

const HandleImportCustomers = (props) => {
  const selectedBusiness = props.selectedBusiness;
  const [csvFields, setCsvFields] = useState([]);
  const [customerFields, setCustomerFields] = useState([
    "Full Name",
    "Cell Phone Number",
    "Email Address",
    "Service Location Address",
    "Subscription Code",
    "Subscription Price",
    "Aggressive Pets",
    "Access Code",
    "Notes",
  ]);

  // make sure swap out customer phone and email so we dont email and text actual customers.

  const handleOnDrop = (data) => {
    console.log(data);
    props.setImportData(data);

    let arrOfFields = [];

    data[0].meta.fields.forEach((field, index) => {
      arrOfFields.push({ name: field, id: index });
      if (data[0].meta.fields.length - 1 === index) {
        setCsvFields(arrOfFields);
      }
    });
    props.setCurrentStep(1);
  };

  const handleOnError = (data) => {
    console.log(data);
    alert("error");
  };

  const handleRemoveFile = (data) => {
    props.setImportData([]);
  };

  return (
    <div
      style={
        props.currentStep === 0
          ? {
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "80%",
              flexDirection: "row",
            }
          : {
              display: "flex",
              width: "100%",
              height: "98%",
              flexDirection: "row",
            }
      }
    >
      {props.currentStep === 0 && (
        <div id="importCustomersMainInnerDiv">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "6%",
            }}
          >
            <div>Import Customers Via CSV</div>
            <div style={{ width: "100%", height: "60%", marginTop: "6%" }}>
              <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                onRemoveFile={handleRemoveFile}
                config={{ header: true }}
              >
                <span>Click to upload.</span>
              </CSVReader>
            </div>
          </div>
        </div>
      )}
      {props.currentStep === 1 && (
        <div id="importCustomersMainInnerDiv">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 60,
            }}
          >
            <div style={{ marginLeft: "2%" }}>
              Total Customers: {props.importData.length}
            </div>
          </div>
          <div
            id="importCustomersAssignFieldsScroll"
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {customerFields.map((value) => (
                <ImportCustomerCard
                  setAssignedFields={props.setAssignedFields}
                  assignedFields={props.assignedFields}
                  value={value}
                  csvFields={csvFields}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HandleImportCustomers;
