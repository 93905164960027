import { BiPencil, BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import { useState, useEffect } from "react";
import { getProfilePhoto } from "../GlobalComponents/getProfilePhoto";

export default function JobStops(props) {
  const db = firebase.firestore();
  const [jobStops, setJobStops] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const handleLoadStops = async () => {
      let stops = [];
      for (let index = 0; index < props.jobStops.length; index++) {
        let stop = props.jobStops[index];

        if (stop.contractor && stop.contractor.id) {
          console.log({
            businessId: stop.businessId,
            uid: stop.contractor.id,
          });
          stop.profilePhoto = await getProfilePhoto({
            businessId: stop.businessId,
            uid: stop.contractor.id,
          });

          stops.push(stop);
        } else {
          stops.push(stop);
        }
      }
      setJobStops(stops);
      setReload(!reload);
    };
    handleLoadStops();
  }, [props.jobStops, props.reloadStops]);

  const handleDeleteJobStop = (stop) => {
    console.log(stop);
    if (stop.stopTech === "none") {
      console.log("unscheduled stop");
      db.collection("Businesses")
        .doc(props.selectedBusiness)
        .collection("Jobs")
        .doc(stop.jobId)
        .update({
          stops: firebase.firestore.FieldValue.arrayRemove(stop),
          unscheduledStops: firebase.firestore.FieldValue.arrayRemove(stop),
        })
        .then(() => {
          let currentJobStops = jobStops;
          let index = currentJobStops.findIndex(
            (a) => a.stopId === stop.stopId
          );
          currentJobStops.splice(index, 1);
          setJobStops(currentJobStops);
          setReload(!reload);

          toast.success("Stop has been deleted!");
        });
    } else {
      console.log("scheduled stop");
      toast.warn("Must first unschedule stop before it can be deleted!");
    }
  };

  return (
    <ul role="list" className="divide-y divide-gray-200">
      {jobStops.map((stop) => (
        <li
          key={stop.stopId}
          style={{ border: "1px solid #D1D5DB", borderRadius: 5 }}
          className="flex items-center h-16 p-2 mt-2"
        >
          <img
            className="h-10 w-10 rounded-full"
            src={
              stop.profilePhoto
                ? stop.profilePhoto
                : "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
            }
            alt=""
          />
          <div className="ml-3 flex">
            <div className="flex flex-col w-32">
              <p className="text-sm font-medium text-gray-900">
                {stop.name || stop.stopTitle}
              </p>
              <p className="text-sm text-gray-500">
                {
                  (stop.address || stop.stopServiceLocation.address).split(
                    ","
                  )[0]
                }
              </p>
            </div>
            <div className="flex flex-col ml-4 w-28">
              <p className="text-sm text-gray-500">
                {stop.contractor ? stop.contractor.name : "Unassigned"}
              </p>
              <p className="text-sm text-gray-500">
                {stop.stopDate === "none" ? "Unassigned" : stop.stopDate}
              </p>
            </div>

            {props.selectedJob &&
              props.selectedJob.status !== "complete" &&
              (stop.completedDates || []).length === 0 && (
                <div
                  onClick={() => props.handleEditStopClick(stop)}
                  className="flex flex-col ml-2 self-center cursor-pointer"
                >
                  <BiPencil size={20} />
                </div>
              )}

            {props.selectedJob &&
              props.selectedJob.status !== "complete" &&
              stop.stopDate !== "none" &&
              (stop.completedDates || []).length !== 0 && (
                <p className="text-sm text-gray-500">Completed</p>
              )}

            {props.selectedJob &&
              props.selectedJob.status !== "complete" &&
              (stop.completedDates || []).length === 0 && (
                <div
                  onClick={() => handleDeleteJobStop(stop)}
                  className="flex flex-col ml-2 self-center cursor-pointer"
                >
                  <BiTrash size={20} />
                </div>
              )}
          </div>
        </li>
      ))}
    </ul>
  );
}
