/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { BiUser } from "react-icons/bi";
import { BiConversation } from "react-icons/bi";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import "../../node_modules/video-react/dist/video-react.css";
import { generateBusinessId } from "../Tools/generateBusinessId";
import firebase from "firebase/compat/app";
import { Rings } from "react-loader-spinner";

export default function NewMessage(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedUser, setSelectedUser] = useState();
  const [sendMessageContent, setSendMessageContent] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      console.log(props.allThreads);
      db.collection("Customers")
        .where("businessIds", "array-contains", props.businessId)
        .onSnapshot((snapshot) => {
          setAllCustomers(snapshot.docs);
        });
    }
  }, [props.open]);

  const handleClose = () => {
    setCurrentStep(0);
    setSelectedUser();
    setSendMessageContent("");
    setEditorState(EditorState.createEmpty());
    props.setOpen(false);
  };

  const handleClickNewMessage = (user) => {
    setLoading(true);
    setSelectedUser(user);
    const threadId = generateBusinessId();
    console.log(user);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/handleInitNewMessageThread",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              fromUid: firebase.auth().currentUser.uid,
              toUid: user.uid,
              threadId: threadId,
              customerId: user.customerId,
            }),
          }
        )
          .then((res) => res.json())
          .then((threadData) => {
            props.addNewThreadToAllThreads(threadData);
            setLoading(false);

            handleClose();
          });
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {" "}
                          New Message Thread{" "}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={handleClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Get started creating a new message thread with the
                          form below.
                        </p>
                      </div>
                    </div>
                    {currentStep === 0 && (
                      <>
                        <div className="border-b border-gray-200">
                          <div class="mx-3 my-3">
                            <div class="relative text-gray-600">
                              <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  viewBox="0 0 24 24"
                                  class="w-6 h-6 text-gray-300"
                                >
                                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                </svg>
                              </span>
                              <input
                                onChange={(change) =>
                                  setSearchTerm(change.target.value)
                                }
                                type="search"
                                class="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
                                name="search"
                                placeholder="Search"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <ul
                          role="list"
                          className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                        >
                          {allCustomers
                            .sort((a, b) =>
                              a
                                .data()
                                .customerFirstName.localeCompare(
                                  b.data().customerFirstName
                                )
                            )
                            .filter(
                              (a) =>
                                !props.allThreads.some((b) =>
                                  b.members.some((x) =>
                                    x.uid.length > 0
                                      ? x.uid === a.data().uid
                                      : x.customerId === a.data().customerId
                                  )
                                )
                            )
                            .filter((a) =>
                              searchTerm !== ""
                                ? (
                                    a.data().customerFirstName.toLowerCase() +
                                    " " +
                                    a.data().customerLastName.toLowerCase()
                                  )
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                : true
                            )
                            .map((person) => (
                              <li key={person.data().customerId}>
                                <div className="group relative flex items-center py-6 px-5">
                                  <div className="-m-1 block flex-1 p-1">
                                    <div
                                      className="absolute inset-0 group-hover:bg-gray-50"
                                      aria-hidden="true"
                                    />
                                    <div className="relative flex min-w-0 flex-1 items-center">
                                      <span className="relative inline-block flex-shrink-0">
                                        <BiUser className="h-10 w-10 rounded-full" />
                                      </span>
                                      <div className="ml-4 truncate">
                                        <p className="truncate text-sm font-medium text-gray-900">
                                          {(
                                            person.data().customerFirstName +
                                            " " +
                                            person.data().customerLastName
                                          ).replace(
                                            /(^\w{1})|(\s+\w{1})/g,
                                            (letter) => letter.toUpperCase()
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        onClick={() =>
                                          handleClickNewMessage(person.data())
                                        }
                                        className="ml-auto truncate cursor-pointer"
                                      >
                                        {loading &&
                                        selectedUser.customerId ===
                                          person.data().customerId ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>Loading </div>
                                            <Rings
                                              height={22}
                                              width={22}
                                              color="blue"
                                              arialLabel="loading-indicator"
                                            />
                                          </div>
                                        ) : (
                                          <BiConversation size={24} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
