import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import DayOfTheWeekCard from "./DayOfWeekCard";
import { getProfilePhoto } from "../../GlobalComponents/getProfilePhoto";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";

const EmployeeCard = (props) => {
  const db = firebase.firestore();
  const [employeeData, setEmployeeData] = useState(props.value);
  const [expandDisplay, setExpandDisplay] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [profilePhoto, setProfilePhoto] = useState();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleProfilePhoto = async () => {
      const profilePhoto = await getProfilePhoto(employeeData);

      setProfilePhoto(profilePhoto);
      props.setEmployeePhotos((prevArray) => [
        ...prevArray,
        { profilePhoto: profilePhoto, uid: employeeData.uid },
      ]);
    };

    handleProfilePhoto();

    const databaseSnapshot = db
      .collection("Businesses")
      .doc(employeeData.businessId)
      .collection("Routes")
      .doc(props.selectedRoute.routeId)
      .collection("Employees")
      .doc(employeeData.uid)
      .collection("Routes")
      .onSnapshot((snapshot) => {
        let totalRouteArray = [];
        snapshot.docs.forEach((dayDocument, index) => {
          const data = dayDocument.data();
          if (data.route) {
            totalRouteArray.push(data.route.length);
          }
          if (snapshot.docs.length - 1 === index) {
            setTotalCount(totalRouteArray.reduce((a, b) => a + b, 0));
          }
        });
      });
    return () => databaseSnapshot();
  }, []);

  const handleDayVisibleToggle = () => {
    if (visible) {
      setVisible(false);
      props.handleToggleHiddenEmployee(false, employeeData.uid);
    } else {
      setVisible(true);
      props.handleToggleHiddenEmployee(true, employeeData.uid);
    }
  };

  return (
    <div
      style={{
        border: "1px solid #E5E7EB",
        borderRadius: 5,
        margin: 12,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          minHeight: 80,
        }}
      >
        <div style={{ marginLeft: "4%" }}>
          <img
            className="inline-block h-10 w-10 rounded-full"
            src={profilePhoto}
          />
        </div>
        <div
          style={{
            marginLeft: "6%",
            width: "23%",
            display: "flex",
            fontSize: "0.8rem",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div>
            {employeeData.name
              .split(" ")[0]
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              )}
          </div>
          <div>
            {employeeData.name.split(" ")[1]
              ? employeeData.name
                  .split(" ")[1]
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )
              : ""}
          </div>
        </div>
        <div
          onClick={() => handleDayVisibleToggle()}
          style={{ margin: 4, cursor: "pointer", marginLeft: "auto" }}
        >
          {visible ? (
            <AiFillEye color="#00A6FF" size={24} />
          ) : (
            <AiOutlineEye color="#00A6FF" size={24} />
          )}
        </div>
        <div style={{ marginLeft: "6%", fontSize: "0.8rem" }}>{totalCount}</div>
        {!expandDisplay && (
          <div
            onClick={() => setExpandDisplay(true)}
            style={{
              marginLeft: "20px",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            <BsChevronDown />
          </div>
        )}
        {expandDisplay && (
          <div
            onClick={() => setExpandDisplay(false)}
            style={{
              marginLeft: "20px",
              marginRight: "16px",
              cursor: "pointer",
            }}
          >
            <BsChevronUp />
          </div>
        )}
      </div>
      {expandDisplay && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            minHeight: 80,
            flexDirection: "column",
            paddingLeft: "4%",
            paddingTop: "4%",
            paddingBottom: "4%",
          }}
        >
          {employeeData.mon && (
            <DayOfTheWeekCard
              editModeDay={props.editModeDay}
              handleEditMode={props.handleEditMode}
              employeeData={employeeData}
              selectedBusiness={props.selectedBusiness}
              selectedRoute={props.selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              handleCancelEditMode={props.handleCancelEditMode}
              color="#976DD0"
              day="Monday"
            />
          )}

          {employeeData.tue && (
            <DayOfTheWeekCard
              editModeDay={props.editModeDay}
              handleEditMode={props.handleEditMode}
              employeeData={employeeData}
              selectedBusiness={props.selectedBusiness}
              selectedRoute={props.selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              handleCancelEditMode={props.handleCancelEditMode}
              color="#FFBA5C"
              day="Tuesday"
            />
          )}

          {employeeData.wed && (
            <DayOfTheWeekCard
              editModeDay={props.editModeDay}
              handleEditMode={props.handleEditMode}
              employeeData={employeeData}
              selectedBusiness={props.selectedBusiness}
              selectedRoute={props.selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              handleCancelEditMode={props.handleCancelEditMode}
              color="#77D353"
              day="Wednesday"
            />
          )}

          {employeeData.thu && (
            <DayOfTheWeekCard
              editModeDay={props.editModeDay}
              handleEditMode={props.handleEditMode}
              employeeData={employeeData}
              selectedBusiness={props.selectedBusiness}
              selectedRoute={props.selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              handleCancelEditMode={props.handleCancelEditMode}
              color="#F95F62"
              day="Thursday"
            />
          )}

          {employeeData.fri && (
            <DayOfTheWeekCard
              editModeDay={props.editModeDay}
              handleEditMode={props.handleEditMode}
              employeeData={employeeData}
              selectedBusiness={props.selectedBusiness}
              selectedRoute={props.selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              handleCancelEditMode={props.handleCancelEditMode}
              color="#235789"
              day="Friday"
            />
          )}

          {employeeData.sat && (
            <DayOfTheWeekCard
              editModeDay={props.editModeDay}
              handleEditMode={props.handleEditMode}
              employeeData={employeeData}
              selectedBusiness={props.selectedBusiness}
              selectedRoute={props.selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              handleCancelEditMode={props.handleCancelEditMode}
              color="#ED7D3A"
              day="Saturday"
            />
          )}

          {employeeData.sun && (
            <DayOfTheWeekCard
              editModeDay={props.editModeDay}
              handleEditMode={props.handleEditMode}
              employeeData={employeeData}
              selectedBusiness={props.selectedBusiness}
              selectedRoute={props.selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              handleCancelEditMode={props.handleCancelEditMode}
              color="blue"
              day="Sunday"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EmployeeCard;
