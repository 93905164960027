import React, { useEffect, useState } from "react";
import CheckBox from "../../GlobalComponents/CheckBox";

const UnscheduledStopCard = (props) => {
  const value = props.value;
  const [checked, setChecked] = useState(
    props.selectedUnscheduledStops.findIndex(
      (a) => a.stopId === value.stopId
    ) !== -1
  );

  const handleChange = () => {
    if (checked) {
      props.handleUnCheckedUnscheduledStop(value);
    } else {
      props.handleCheckedUnscheduledStop(value);
    }

    setChecked(!checked);
  };

  return (
    <div
      key={value.serviceLocationId}
      style={{
        border: "1px solid black",
        height: 40,
        display: "flex",
        alignItems: "center",
        width: "88%",
        margin: "auto",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "2%" }}
      >
        <div style={{ fontSize: "0.8rem", fontWeight: "600" }}>
          {(value.customer
            ? value.customer.customerName
              ? value.customer.customerName
              : value.customer.name
            : value.customerData.name
          ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            fontSize: "0.6rem",
          }}
        >
          {value.stopTitle
            ? value.stopTitle.substring(0, 20)
            : value.stopNickname}
          ...
        </div>
      </div>

      <div
        onClick={() => props.handleViewDetails(value)}
        style={{
          marginLeft: "auto",
          marginRight: "4%",
          fontSize: "0.68rem",
          cursor: "pointer",
        }}
      >
        View Details
      </div>

      <div style={{ marginRight: "4%", cursor: "pointer" }}>
        <CheckBox onChange={handleChange} checked={checked} />
      </div>
    </div>
  );
};

export default UnscheduledStopCard;
