import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { RiArrowGoBackFill } from "react-icons/ri";
import { AiOutlineCheck } from "react-icons/ai";

function stripHTMLTags(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ");
}

const MessageCard = ({
  value,
  selectedMessage,
  handleSelectMessageThread,
  handleMarkThreadOpen,
  handleMarkThreadDone,
}) => {
  const [messagePreview, setMessagePreview] = useState("");
  const db = firebase.firestore();

  useEffect(() => {
    const listener = db
      .collection("Messages")
      .doc(value.threadId)
      .collection("Messages")
      .orderBy("date", "desc")
      .limit(1)
      .onSnapshot((documentSnapshot) => {
        if (documentSnapshot.docs[0]) {
          const latestMessage = documentSnapshot.docs[0].data();
          console.log(latestMessage);
          setMessagePreview(latestMessage.body);
        }
      });
    return () => listener();
  }, [value]);

  return (
    <div
      style={{
        borderBottom: "1px solid #D1D5DB",
        backgroundColor: selectedMessage
          ? selectedMessage.threadId === value.threadId
            ? "#796DFE"
            : "white"
          : "white",
      }}
      key={value.threadId}
      onClick={() => handleSelectMessageThread(value)}
      class="flex items-center px-2 py-4 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none"
    >
      <div class="w-full">
        <div class="flex items-center">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              paddingLeft: 14,
              width: "70%",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {value.members
              .filter((a) => a.uid !== firebase.auth().currentUser.uid)
              .map((member) => (
                <div
                  style={{
                    textAlign: "left",
                    color: selectedMessage
                      ? selectedMessage.threadId === value.threadId
                        ? "white"
                        : "#6B7280"
                      : "#6B7280",
                  }}
                >
                  {member.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}
                </div>
              ))}
            <div
              style={{
                textAlign: "left",
                color: selectedMessage
                  ? selectedMessage.threadId === value.threadId
                    ? "white"
                    : "#6B7280"
                  : "#6B7280",
              }}
            >
              {stripHTMLTags(messagePreview)}
            </div>
          </div>

          <div
            style={{
              height: 10,
              width: 10,
              backgroundColor: value.members[
                value.members.findIndex(
                  (x) => x.uid === firebase.auth().currentUser.uid
                )
              ].unread
                ? "red"
                : "transparent",
              borderRadius: 50,
              marginLeft: "auto",
              marginRight: "6%",
            }}
          ></div>
          <div
            onClick={(event) => handleMarkThreadOpen(value, event)}
            style={{
              marginLeft: "auto",
              marginRight: "8%",
              cursor: "pointer",
              display:
                value.members[
                  value.members.findIndex(
                    (x) => x.uid === firebase.auth().currentUser.uid
                  )
                ].threadStatus === "done"
                  ? "flex"
                  : "none",
            }}
          >
            <RiArrowGoBackFill
              color={selectedMessage === value ? "white" : "#6B7280"}
              size={18}
            />
          </div>

          <div
            onClick={(event) => handleMarkThreadDone(value, event)}
            style={{
              marginLeft: "auto",
              marginRight: "8%",
              cursor: "pointer",
              display:
                value.members[
                  value.members.findIndex(
                    (x) => x.uid === firebase.auth().currentUser.uid
                  )
                ].threadStatus !== "done"
                  ? "flex"
                  : "none",
            }}
          >
            <AiOutlineCheck
              color={selectedMessage === value ? "white" : "#6B7280"}
              size={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
