import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import { BiTrash } from "react-icons/bi";
import NewCommunication from "./NewCommunication";

export default function Communication(props) {
  const selectedBusiness = props.businessId;
  const db = firebase.firestore();
  const [communications, setCommunications] = useState([]);
  const [createCommunicationDisplay, setCreateCommunicationDisplay] =
    useState(false);
  const searchTerm = props.searchTerm;

  useEffect(() => {
    console.log(selectedBusiness);
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Communication")
      .onSnapshot((snapshot) => {
        let communicationsArray = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const communicationDoc = snapshot.docs[index].data();
          communicationsArray.push(communicationDoc);
        }
        setCommunications(communicationsArray);
      });
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Communication Notifications
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setCreateCommunicationDisplay(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Create +
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Notification
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {communications.map((communication) => (
                    <tr key={communication.communicationId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div
                          style={{
                            maxWidth: "120%",
                            overflow: "hidden",
                            whiteSpace: "normal",
                          }}
                        >
                          {communication.body}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {communication.date}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {communication.time}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewCommunication
        open={createCommunicationDisplay}
        setOpen={setCreateCommunicationDisplay}
      />
    </div>
  );
}
