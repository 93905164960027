export default function JobNotes(props) {
  return (
    <ul role="list" className="divide-y divide-gray-200">
      {props.jobNotes.map((note) => (
        <li key={note.noteBody} className="py-4 flex items-center">
          <div
            style={{
              border: "1px solid black",
              borderRadius: 50,
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 3,
            }}
          >
            <p className="text-sm font-medium text-gray-900">
              {note.noteWho.charAt(0)}
            </p>
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">{note.noteBody}</p>
          </div>
          <div className="ml-auto">
            <p className="text-sm font-medium text-gray-900">{note.noteWhen}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}
