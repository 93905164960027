/* eslint-disable no-loop-func */
import React, { useState, useRef, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import dayjs from "dayjs";
import firebase from "firebase/compat/app";

const EditRouteMenu = (props) => {
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [active, setActive] = useState(false);
  const routeIsAlreadyActive = props.routeIsAlreadyActive;
  const cooldown = useRef();

  useEffect(() => {
    cooldown.current = false;
  }, []);

  return (
    <div
      onClick={() => props.handleAddCustomersClick()}
      style={{
        width: 48,
        height: 48,
        border: "1px solid gray",
        borderRadius: 50,
        backgroundColor: "#46A6F8",
        position: "absolute",
        zIndex: 10,
        right: 50,
        bottom: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <GiHamburgerMenu color="white" size={30} />
    </div>
  );
};

export default EditRouteMenu;
