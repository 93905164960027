export default function JobStops(props) {
  return (
    <ul role="list" className="divide-y divide-gray-200">
      {props.jobStops.map((stop) => (
        <li key={stop.stopId} className="py-4 flex">
          <img
            className="h-10 w-10 rounded-full"
            src={
              stop.contractor
                ? stop.contractor.profilePhoto
                : "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/5cmw0XjtlxWlf1apUBF7r4W1rH92profilePhoto.png?alt=media&token=6ea75e8a-f9e5-432d-89d2-f4551d20d526"
            }
            alt=""
          />
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              {stop.name
                ? stop.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )
                : stop.stopTitle.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}
            </p>
            <p className="text-sm text-gray-500">
              {stop.address
                ? stop.address.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )
                : stop.stopServiceLocation.address.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter) => letter.toUpperCase()
                  )}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
}
