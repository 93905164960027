import { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { RiDashboardLine } from "react-icons/ri";
import { IoPersonCircleOutline, IoMapSharp } from "react-icons/io5";
import { BsFilePerson } from "react-icons/bs";
import { FaMoneyCheckAlt, FaRoute, FaUserAlt } from "react-icons/fa";
import { BiMessageAltDetail, BiWrench } from "react-icons/bi";
import { GiChemicalDrop } from "react-icons/gi";
import logo from "./Assets/symbrilogo.png";
import Customers from "./Customers/Customers";
import ActiveRoute from "./Routes/ActiveRoutes/ActiveRoute";
import RouteBuilder from "./Routes/RouteBuilder/RouteBuilderHomeNew";
import Employees from "./Employees/Employees";
import Subscriptions from "./Subscriptions/Subscriptions";
import CompanyMessages from "./Messages/CompanyMessages";
import Chemicals from "./Chemicals/Chemicals";
import Jobs from "./Jobs/Jobs";
import DashboardBreadcrumbs from "./DashboardBreadcrumbs";
import { FiList, FiSettings } from "react-icons/fi";
import Settings from "./Settings/Settings";
import Tasks from "./Tasks/Tasks";
import firebase from "firebase/compat/app";
import Communication from "./Communication/Communication";
const userNavigation = [{ name: "Sign out", href: "#" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const db = firebase.firestore();
  const [profilePhoto, setProfilePhoto] = useState(
    "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
  );
  const businessId = props.businessId;
  const [searchTerm, setSearchTerm] = useState("");
  const [preSelectedCustomer, setPreSelectedCustomer] = useState();
  const [userPermissions, setUserPermissions] = useState([]);
  const [reloadCustomers, setReloadCustomers] = useState(false);
  const [reloadEmployees, setReloadEmployees] = useState(false);
  const [reloadSubscriptions, setReloadSubscriptions] = useState(false);
  const [reloadRouteBuilder, setReloadRouteBuilder] = useState(false);
  const [navigation, setNavigation] = useState([
    {
      name: "Customers",
      href: "customers",
      icon: IoPersonCircleOutline,
      current: true,
    },
    { name: "Employees", href: "#", icon: BsFilePerson, current: false },
    {
      name: "Subscriptions",
      href: "#",
      icon: FaMoneyCheckAlt,
      current: false,
    },
    { name: "Messages", href: "#", icon: BiMessageAltDetail, current: false },
    { name: "Active Route", href: "#", icon: IoMapSharp, current: false },
    { name: "Route Builder", href: "#", icon: FaRoute, current: false },
    { name: "Communication", href: "#", icon: BellIcon, current: false },
    { name: "Jobs", href: "#", icon: BiWrench, current: false },
    { name: "Issue Tracker", href: "#", icon: FiList, current: false },
    { name: "Chemicals", href: "#", icon: GiChemicalDrop, current: false },
    { name: "Settings", href: "#", icon: FiSettings, current: false },
  ]);

  const [refresh, setRefresh] = useState(false);

  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    let selectedNav = navigation[navigation.findIndex((a) => a.current)];
    setBreadCrumbs([{ name: selectedNav.name, type: "main", current: false }]);
  }, [navigation.findIndex((a) => a.current)]);

  useEffect(() => {
    setUserPermissions([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
    ]);

    const handleProfilePhoto = async () => {
      try {
        if (firebase.auth().currentUser && businessId) {
          db.collection("Businesses")
            .doc(businessId)
            .collection("Workers")
            .doc(firebase.auth().currentUser.uid)
            .onSnapshot((documentSnapshot) => {
              const data = documentSnapshot.data();
              setProfilePhoto(data.profilePhoto);
            });
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleProfilePhoto();
  }, [businessId]);

  const handleChangeNavigation = (itemName) => {
    setSearchTerm("");
    let currentNavigation = navigation;

    let indexOfCurrent = currentNavigation.findIndex((a) => a.current);

    currentNavigation[indexOfCurrent].current = false;

    let indexOfNavObject = currentNavigation.findIndex(
      (a) => a.name === itemName
    );

    currentNavigation[indexOfNavObject].current = true;
    setNavigation(currentNavigation);

    setRefresh(!refresh);
  };

  const handleAddToBreadcrumb = (value) => {
    let currentBreadcrumbs = breadCrumbs;
    currentBreadcrumbs.push(value);
    setBreadCrumbs(currentBreadcrumbs);
    setRefresh(!refresh);
  };

  const handleRemoveFromBreadcrumb = (value) => {
    let currentBreadcrumbs = breadCrumbs;
  };

  const handleBreadcrumbClick = (value) => {
    console.log(value);
    // remove all breadcrumbs after this one
    let currentBreadcrumbs = breadCrumbs;
    let indexOfValue = currentBreadcrumbs.findIndex(
      (a) => a.name === value.name
    );
    currentBreadcrumbs.splice(
      indexOfValue + 1,
      currentBreadcrumbs.length - (indexOfValue + 1)
    );
    setBreadCrumbs(currentBreadcrumbs);
    setRefresh(!refresh);

    // need to setup functions to handle going back for specific situations

    if (value.type === "customer") {
      // need customer profile component to go back to customer
      setPreSelectedCustomer(value.value);
      setReloadCustomers(!reloadCustomers);
    } else if (value.type === "main") {
      handleChangeNavigation(value.name);
      if (value.name === "Customers") {
        setPreSelectedCustomer();
        setReloadCustomers(!reloadCustomers);
      } else if (value.name === "Employees") {
        setReloadEmployees(!reloadEmployees);
      } else if (value.name === "Route Builder") {
        setReloadRouteBuilder(!reloadRouteBuilder);
      } else if (value.name === "Subscriptions") {
        setReloadSubscriptions(!reloadSubscriptions);
      }
    }
  };

  const resetBreadcrumbForRoute = () => {
    setBreadCrumbs([{ name: "Route Builder", type: "main", current: false }]);
    setRefresh(!refresh);
  };

  const handleSignout = () => {
    firebase.auth().signOut();
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img className="h-8 w-auto" src={logo} alt="Workflow" />
                    <div style={{ color: "white", marginLeft: "4%" }}>
                      {props.businessName}
                    </div>
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <div
                          key={item.name}
                          onClick={() => handleChangeNavigation(item.name)}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
              <img className="h-8 w-auto" src={logo} alt="Workflow" />
              <div style={{ color: "white", marginLeft: "4%" }}>
                {props.businessName}
              </div>
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 px-2 py-4 space-y-1">
                {navigation.map((item) => (
                  <div
                    key={item.name}
                    //href={item.href}
                    onClick={() => handleChangeNavigation(item.name)}
                    className={classNames(
                      item.current
                        ? "bg-indigo-600 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                <div className="w-full flex md:ml-0">
                  <DashboardBreadcrumbs
                    handleBreadcrumbClick={handleBreadcrumbClick}
                    breadCrumbs={breadCrumbs}
                  />
                </div>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                {!navigation[navigation.findIndex((a) => a.name === "Messages")]
                  .current && (
                  <div className="mt-1">
                    <input
                      value={searchTerm}
                      placeholder="Enter search term..."
                      onChange={(change) => setSearchTerm(change.target.value)}
                      type="text"
                      className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10 w-80"
                    />
                  </div>
                )}

                <Menu as="div" className="ml-6 relative mr-3">
                  <div key={profilePhoto}>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={profilePhoto}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          <div
                            onClick={() => handleSignout()}
                            className={classNames(
                              "block px-4 py-2 text-sm text-red-600 cursor-pointer"
                            )}
                          >
                            {item.name}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">
            {navigation.findIndex((a) => a.name === "Customers") !== -1 &&
              navigation[navigation.findIndex((a) => a.name === "Customers")]
                .current && (
                <div className="py-6">
                  <Customers
                    key={reloadCustomers}
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                    preSelectedCustomer={preSelectedCustomer}
                  />
                </div>
              )}
            {navigation.findIndex((a) => a.name === "Employees") !== -1 &&
              navigation[navigation.findIndex((a) => a.name === "Employees")]
                .current && (
                <div className="py-6">
                  <Employees
                    key={reloadEmployees}
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                  />
                </div>
              )}
            {navigation.findIndex((a) => a.name === "Active Route") !== -1 &&
              navigation[navigation.findIndex((a) => a.name === "Active Route")]
                .current && (
                <div className="py-6">
                  <ActiveRoute
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                  />
                </div>
              )}
            {navigation.findIndex((a) => a.name === "Route Builder") !== -1 &&
              navigation[
                navigation.findIndex((a) => a.name === "Route Builder")
              ].current && (
                <div className="py-6">
                  <RouteBuilder
                    resetBreadcrumbForRoute={resetBreadcrumbForRoute}
                    key={reloadRouteBuilder}
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                  />
                </div>
              )}
            {navigation.findIndex((a) => a.name === "Subscriptions") !== -1 &&
              navigation[
                navigation.findIndex((a) => a.name === "Subscriptions")
              ].current && (
                <div className="py-6">
                  <Subscriptions
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                    reloadSubscriptions={reloadSubscriptions}
                    setReloadSubscriptions={setReloadSubscriptions}
                  />
                </div>
              )}
            {navigation.findIndex((a) => a.name === "Chemicals") !== -1 &&
              navigation[navigation.findIndex((a) => a.name === "Chemicals")]
                .current && (
                <div className="py-6">
                  <Chemicals
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                  />
                </div>
              )}

            {navigation.findIndex((a) => a.name === "Communication") !== -1 &&
              navigation[
                navigation.findIndex((a) => a.name === "Communication")
              ].current && (
                <div className="py-6">
                  <Communication
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                  />
                </div>
              )}

            {navigation.findIndex((a) => a.name === "Issue Tracker") !== -1 &&
              navigation[
                navigation.findIndex((a) => a.name === "Issue Tracker")
              ].current && (
                <div className="py-6">
                  <Tasks
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                  />
                </div>
              )}
            {navigation.findIndex((a) => a.name === "Jobs") !== -1 &&
              navigation[navigation.findIndex((a) => a.name === "Jobs")]
                .current && (
                <div className="py-6">
                  <Jobs
                    handleAddToBreadcrumb={handleAddToBreadcrumb}
                    handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                    searchTerm={searchTerm}
                    businessId={businessId}
                  />
                </div>
              )}
            {navigation.findIndex((a) => a.name === "Messages") !== -1 &&
              navigation[navigation.findIndex((a) => a.name === "Messages")]
                .current && (
                <CompanyMessages
                  handleAddToBreadcrumb={handleAddToBreadcrumb}
                  handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                  searchTerm={searchTerm}
                  selectedBusiness={businessId}
                  userPermissions={userPermissions}
                />
              )}
            {navigation.findIndex((a) => a.name === "Settings") !== -1 &&
              navigation[navigation.findIndex((a) => a.name === "Settings")]
                .current && (
                <Settings
                  handleAddToBreadcrumb={handleAddToBreadcrumb}
                  handleRemoveFromBreadcrumb={handleRemoveFromBreadcrumb}
                  searchTerm={searchTerm}
                  businessId={businessId}
                  userPermissions={userPermissions}
                  businessName={props.businessName}
                  setBusinessName={props.setBusinessName}
                />
              )}
          </main>
        </div>
      </div>
    </>
  );
}
