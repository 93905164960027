import React, { useState } from "react";
import Dashboard from "./Dashboard";
import Login from "./LoginNew/Login";
import { useEffect } from "react";
import firebase from "firebase/compat/app";

const AuthManager = () => {
  const [userAuth, setUserAuth] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [authPending, setAuthPending] = useState(true);
  const db = firebase.firestore();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("Users")
          .doc(user.uid)
          .get()
          .then((documentSnapshot) => {
            const userData = documentSnapshot.data();
            if (!userData) {
              firebase.auth().signOut();
              return;
            }
            if (
              userData.userType === "worker" ||
              userData.userType === "owner" ||
              userData.userType === "business"
            ) {
              setBusinessId(userData.businessId);
              db.collection("Businesses")
                .doc(userData.businessId)
                .get()
                .then((documentSnapshot) => {
                  if (!documentSnapshot.exists) {
                    setUserAuth(false);
                    setAuthPending(false);
                    return;
                  }
                  const businessDaata = documentSnapshot.data();
                  setBusinessName(businessDaata.name);
                  setUserAuth(true);
                  setAuthPending(false);
                });
            }
          });
      } else {
        setUserAuth(false);
        setAuthPending(false);
      }
    });
  }, []);

  return authPending ? (
    <div></div>
  ) : (
    <div id="root">
      {userAuth ? (
        <Dashboard
          setBusinessName={setBusinessName}
          businessName={businessName}
          businessId={businessId}
        />
      ) : (
        <Login
          setBusinessName={setBusinessName}
          setBusinessId={setBusinessId}
          setUserAuth={setUserAuth}
        />
      )}
    </div>
  );
};

export default AuthManager;
