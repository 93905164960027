import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import HandleImportEmployees from "./HandleImportEmployees";

export default function ImportEmployees(props) {
  const [open, setOpen] = useState(false);
  const selectedBusiness = props.businessId;
  const [currentStep, setCurrentStep] = useState(0);
  const [importData, setImportData] = useState([]);
  const [assignedFields, setAssignedFields] = useState([]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
    setImportData([]);
    setAssignedFields([]);
    setCurrentStep(0);
  };

  const handleImportEmployees = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/handleImportEmployees",
          {
            method: "POST",
            body: JSON.stringify({
              importData: importData,
              selectedBusiness: { id: selectedBusiness },
              assignedFields: assignedFields,
              token: token,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => console.log(data));
      });
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleClick = () => {
    handleImportEmployees();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            Import Employees{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started importing employees by completing the
                            form below.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <HandleImportEmployees
                            setCurrentStep={setCurrentStep}
                            currentStep={currentStep}
                            setImportData={setImportData}
                            importData={importData}
                            setAssignedFields={setAssignedFields}
                            assignedFields={assignedFields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      {currentStep === 1 && (
                        <button
                          onClick={handleClick}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Import
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
