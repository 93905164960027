import { Fragment, useEffect, useState } from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid/";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectAssigned(props) {
  const [selected, setSelected] = useState(
    props.selectedFolder ? props.selectedFolder : "new"
  );
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    console.log(props.allFolders);
    if (props.allFolders) {
      setOptions(props.allFolders);
    }
  }, [props.allFolders]);

  const handleChange = (value) => {
    props.setSelectedFolder(value);
    setSelected(value);
  };
  // NEITHER CATEGORIES OR FOLDERS GET REASSIGNED TO ALL WHEN YOU DELETE A CATEGORY OR FOLDER, PROBABLY NEED TO FIX THIS.
  // FOUND BUG IN EMAIL REPORTS NEED TO FIX THEY NOT ALWAYS SENDING
  return (
    <Combobox as="div" value={selected} onChange={handleChange}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900"></Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          readOnly
          displayValue={(person) =>
            person.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            )
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options
            .filter((a) =>
              searchTerm !== ""
                ? a.toLowerCase().includes(searchTerm.toLowerCase())
                : true
            )
            .map((option) => (
              <Combobox.Option
                key={option}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {option.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                      )}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          <Combobox.Option
            value={props.header ? "All" : "None"}
            className={({ active }) =>
              classNames(
                "relative cursor-default select-none py-2 pl-3 pr-9",
                active ? "bg-indigo-600 text-white" : "text-gray-900"
              )
            }
          >
            {({ active, selected }) => (
              <>
                <span
                  className={classNames(
                    "block truncate",
                    selected && "font-semibold"
                  )}
                >
                  {props.header ? "All" : "None"}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      "absolute inset-y-0 right-0 flex items-center pr-4",
                      active ? "text-white" : "text-indigo-600"
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
