import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import CheckBox from "../../GlobalComponents/CheckBox";
import SelectEmployee from "./SelectEmployee";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { toast } from "react-toastify";

const daysOfWeek = [
  { id: "mon", title: "Mon" },
  { id: "tue", title: "Tue" },
  { id: "wed", title: "Wed" },
  { id: "thu", title: "Thu" },
  { id: "fri", title: "Fri" },
  { id: "sat", title: "Sat" },
  { id: "sun", title: "Sun" },
];

export default function AddEmployees(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const selectedRoute = props.selectedRoute;
  const [selectedEmployee, setSelectedEmployee] = useState();
  const selectedBusiness = props.selectedBusiness;

  useEffect(() => {
    setOpen(props.open);

    if (props.open) {
      setEmployees(props.employeesInRoute);
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Workers")
        .orderBy("name")
        .get()
        .then((snapshot) => {
          let arrOfEmployees = [];
          snapshot.docs.forEach((value) => {
            const data = value.data();

            if (
              props.employeesInRoute.findIndex((a) => a.uid === data.uid) === -1
            ) {
              arrOfEmployees.push({
                name: data.name,
                uid: data.uid,
                businessId: selectedBusiness,
                photo: data.profilePhoto,
                userType: data.userType,
              });
            }
          });

          setAllEmployees(arrOfEmployees);
        });
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleCheckboxClicked = (day, boolean, employee) => {
    const value = !boolean;

    let currentEmployees = employees;

    let indexOfEmployee = employees.findIndex((a) => a.uid === employee.uid);

    currentEmployees[indexOfEmployee][day] = value;

    setEmployees(currentEmployees);

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .doc(employee.uid)
      .update({ [day]: value });
  };

  const handleImportEmployee = () => {
    if (selectedEmployee) {
      const value = selectedEmployee;
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("Employees")
        .doc(value.uid)
        .get()
        .then((documentSnapshot) => {
          if (!documentSnapshot.exists) {
            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(selectedRoute.routeId)
              .collection("Employees")
              .doc(value.uid)
              .set({
                name: value.name,
                uid: value.uid,
                businessId: value.businessId,
                photo: value.photo,
                mon: true,
                tue: true,
                wed: true,
                thu: true,
                fri: false,
                sat: false,
                sun: false,
              })
              .then(() => {
                db.collection("Businesses")
                  .doc(selectedBusiness)
                  .collection("Routes")
                  .doc(selectedRoute.routeId)
                  .collection("Employees")
                  .doc(value.uid)
                  .collection("Routes")
                  .doc("Pending")
                  .set({
                    pending: true,
                  })
                  .then(() => {
                    let currentEmployees = employees;

                    currentEmployees.push({
                      name: value.name,
                      uid: value.uid,
                      businessId: value.businessId,
                      photo: value.photo,
                      mon: true,
                      tue: true,
                      wed: true,
                      thu: true,
                      fri: false,
                      sat: false,
                      sun: false,
                    });

                    setEmployees(currentEmployees);

                    setRefresh(!refresh);
                  });
              });
          } else {
            toast.warn("Employee already added to route!");
          }
        });
    } else {
      toast.warn("Must select employee to add!");
    }
  };

  const handleDelete = (person) => {
    props.handleDeleteEmployee(person);
    let currentEmployees = employees;

    let indexOfEmployee = employees.findIndex((a) => a.uid === person.uid);

    currentEmployees.splice(indexOfEmployee, 1);

    setEmployees(currentEmployees);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {" "}
                          Employees{" "}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={handleClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Use this panel to add employees and select which days
                          they are active on!
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 60,
                      }}
                    >
                      <div style={{ width: 360, marginLeft: 20 }}>
                        <SelectEmployee
                          allEmployees={allEmployees}
                          setSelectedEmployee={setSelectedEmployee}
                        />
                      </div>
                      <div
                        onClick={handleImportEmployee}
                        style={{
                          marginLeft: "auto",
                          marginRight: 12,
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlinePlusCircle size={30} />
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav
                          className="-mb-px flex space-x-6"
                          x-descriptions="Tab component"
                        ></nav>
                      </div>
                    </div>
                    <div className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {employees.map((person) => (
                        <div key={person.name}>
                          <div className="group relative flex items-center py-6 px-5">
                            <a
                              href={person.href}
                              className="-m-1 block flex-1 p-1"
                            >
                              <div
                                className="absolute inset-0 group-hover:bg-gray-50"
                                aria-hidden="true"
                              />
                              <div className="relative flex min-w-0 flex-1 items-center">
                                <span className="relative inline-block flex-shrink-0 flex flex-col items-center">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={person.photo}
                                    alt=""
                                  />
                                  <div
                                    onClick={() => handleDelete(person)}
                                    style={{
                                      color: "red",
                                      fontSize: "0.8rem",
                                      marginTop: 8,
                                      cursor: "pointer",
                                    }}
                                  >
                                    delete
                                  </div>
                                </span>
                                <div className="ml-4 truncate">
                                  <p className="truncate text-sm font-medium text-gray-900">
                                    {person.name}
                                  </p>
                                  <div className="truncate text-sm text-gray-500">
                                    <div>
                                      <div className="mt-4">
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
                                          {daysOfWeek.slice(0, 4).map((day) => (
                                            <div
                                              key={day.id}
                                              className="flex items-center w-20"
                                            >
                                              <CheckBox
                                                onChange={() =>
                                                  handleCheckboxClicked(
                                                    day.id,
                                                    person[day.id],
                                                    person
                                                  )
                                                }
                                                checked={person[day.id]}
                                              />

                                              <label
                                                htmlFor={day.id}
                                                className="ml-2 block text-sm font-medium text-gray-700"
                                              >
                                                {day.title}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2 mt-2">
                                          {daysOfWeek.slice(4, 8).map((day) => (
                                            <div
                                              key={day.id}
                                              className="flex items-center w-20"
                                            >
                                              <CheckBox
                                                onChange={() =>
                                                  handleCheckboxClicked(
                                                    day.id,
                                                    person[day.id],
                                                    person
                                                  )
                                                }
                                                checked={person[day.id]}
                                              />
                                              <label
                                                htmlFor={day.id}
                                                className="ml-2 block text-sm font-medium text-gray-700"
                                              >
                                                {day.title}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
