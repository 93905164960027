import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import JobStops from "./JobStops";
import JobNotes from "./JobNotes";
import JobImages from "./JobImages";
import dayjs from "dayjs";
import { generateBusinessId } from "../Tools/generateBusinessId";
import Geocoder from "react-native-geocoding";
import { toast } from "react-toastify";
import SelectEmployeeSearch from "./SelectEmployeeSearch";
import ViewImage from "../GlobalComponents/ViewImage";
Geocoder.init(process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN);

export default function EditJob(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;
  const [jobName, setJobName] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobStops, setJobStops] = useState([]);
  const [jobNotes, setJobNotes] = useState([]);
  const [jobPhotos, setJobPhotos] = useState([]);
  const [addNoteDisplay, setAddNoteDisplay] = useState(false);
  const [addStopDisplay, setAddStopDisplay] = useState(false);
  const [editStopDisplay, setEditStopDisplay] = useState(false);
  const [editStopData, setEditStopData] = useState({});
  const [newNoteContent, setNewNoteContent] = useState("");
  const [stopTitle, setstopTitle] = useState("");
  const [stopDescription, setStopDescription] = useState("");
  const [reloadStops, setReloadStops] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [rescheduleEmployee, setRescheduleEmployee] = useState({});
  const [rescheduleDisplay, setRescheduleDisplay] = useState(false);
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedJob, setSelectedJob] = useState(props.selectedJob);
  const [viewImage, setViewImage] = useState(false);
  const [media, setMedia] = useState();

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Jobs")
        .doc(props.selectedJob.jobId)
        .onSnapshot((documentShapshot) => {
          const data = documentShapshot.data();
          if (data) {
            setSelectedJob(data);
            setJobName(data.jobTitle);
            setJobDescription(data.jobDescription);
            setJobNotes(data.notes);
            console.log(data.stops);
            const unscheduled = data.unscheduledStops
              ? data.unscheduledStops
              : [];
            const stops = [...data.stops, ...unscheduled];
            setJobStops(stops);
            setJobPhotos(data.pics);
            setReloadStops(!reloadStops);
          }
        });
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setEditStopDisplay(false);
    setRescheduleDisplay(false);
    props.setOpen(false);
  };

  const handleUpdateJobName = (value) => {
    if (value === "" || value.trim().length === 0) {
      // need to also validate number format
      toast.warn("Job name field is required!");
      return;
    }
    setJobName(value);
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .doc(selectedJob.jobId)
      .update({
        jobTitle: value,
      })
      .catch((e) => console.log(e));
  };

  const handleUpdateJobDescription = (value) => {
    if (value === "" || value.trim().length === 0) {
      // need to also validate number format
      toast.warn("Job description field is required!");
      return;
    }
    setJobDescription(value);
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .doc(selectedJob.jobId)
      .update({
        jobDescription: value,
      })
      .catch((e) => console.log(e));
  };

  const handleCompleteJob = () => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .doc(selectedJob.jobId)
      .update({
        status: "complete",
      })
      .then(() => {
        handleClose();
      })
      .catch((e) => console.log(e));
  };

  const handleAddNote = () => {
    if (newNoteContent !== "") {
      db.collection("Users")
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((documentSnapshot) => {
          const userData = documentSnapshot.data();

          const note = {
            noteBody: newNoteContent,
            noteWhen: dayjs().format("MM/DD/YYYY"),
            noteWho: userData.name,
          };

          db.collection("Businesses")
            .doc(props.selectedBusiness)
            .collection("Jobs")
            .doc(selectedJob.jobId)
            .update({
              notes: firebase.firestore.FieldValue.arrayUnion(note),
            })
            .then(() => {
              let currentLocalNotes = jobNotes;
              currentLocalNotes.push(note);
              setJobNotes(currentLocalNotes);
              console.log("saved");
              setNewNoteContent("");
              setAddNoteDisplay(false);
            });
        });
    } else {
      alert("Note body required!");
    }
  };

  const handleCreateStop = async () => {
    if (stopTitle === "" || stopTitle.trim().length === 0) {
      toast.warn("Stop title field is required!");
      return;
    } else if (stopDescription === "" || stopDescription.trim().length === 0) {
      toast.warn("Stop description field is required!");
      return;
    }

    const stopObject = {
      stopDate: "none",
      stopTitle: stopTitle,
      stopDescription: stopDescription,
      stopTech: "none",
      scheduledJob: false,
      jobId: selectedJob.jobId,
      stopId: generateBusinessId(),
      customer: {
        customerName:
          selectedJob.customerData.customerFirstName +
          " " +
          selectedJob.customerData.customerLastName,
        customerId: selectedJob.customerData.customerId,
      },
      stopServiceLocation: selectedJob.serviceLocation,
      stopServiceLocationCoordinates: await Geocoder.from(
        selectedJob.serviceLocation.address
      ).then((json) => {
        return json.results[0].geometry.location;
      }),
    };

    // replace this shit with new notification

    db.collection("Businesses")
      .doc(props.selectedBusiness)
      .collection("Jobs")
      .doc(selectedJob.jobId)
      .update({
        unscheduledStops: firebase.firestore.FieldValue.arrayUnion(stopObject),
      })
      .then(() => {
        db.collection("Businesses")
          .doc(props.selectedBusiness)
          .get()
          .then((documentSnapshot) => {
            const businessData = documentSnapshot.data();

            db.collection("Businesses")
              .doc(props.selectedBusiness)
              .collection("Jobs")
              .doc(selectedJob.jobId)
              .get()
              .then((documentSnapshot) => {
                const data = documentSnapshot.data();
                firebase
                  .auth()
                  .currentUser.getIdToken()
                  .then((token) => {
                    fetch(
                      "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                      {
                        method: "POST",
                        body: JSON.stringify({
                          token: token,
                          notificationData: {
                            hasApp: {
                              body:
                                "A new stop named " +
                                stopTitle +
                                " for the job " +
                                data.jobTitle +
                                " has been created but is unscheduled.",
                              title: "Unscheduled job stop created!",
                            },
                            noApp: {
                              body:
                                "A new stop named " +
                                stopTitle +
                                " for the job " +
                                data.jobTitle +
                                " has been created but is unscheduled. Please download the app and login into view the the stop details and message " +
                                businessData.name +
                                " with any questions.",
                              title: "Unscheduled job stop created!",
                            },
                          },
                          customerId: selectedJob.customerData.customerId,
                        }),
                      }
                    );
                  });
              });
          });
        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Routes")
          .where("active", "==", true)
          .get()
          .then(async (routeQuery) => {
            await db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("Routes")
              .doc(routeQuery.docs[0].id)
              .update({
                reload: !routeQuery.docs[0].data().reload,
              });
            let x = jobStops;
            x.push(stopObject);
            setJobStops(x);
            setstopTitle("");
            setStopDescription("");

            setAddStopDisplay(false);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleEditStopClick = (stop) => {
    setEditStopData(stop);
    console.log(stop);
    setstopTitle(stop.stopTitle ? stop.stopTitle : stop.name);
    setStopDescription(
      stop.stopDescription ? stop.stopDescription : stop.stopWorkerDescription
    );
    console.log(
      stop.stopDescription ? stop.stopDescription : stop.stopWorkerDescription
    );
    setEditStopDisplay(true);
  };

  const handleEditStopSave = (stop) => {
    if (stop.stopDescription) {
      db.collection("Businesses")
        .doc(props.selectedBusiness)
        .collection("Jobs")
        .doc(selectedJob.jobId)
        .get()
        .then((documentSnapshot) => {
          const data = documentSnapshot.data();
          let unscheduledStops = data.unscheduledStops;
          const indexOfEditedStop = unscheduledStops.findIndex(
            (a) => a.stopId === stop.stopId
          );
          unscheduledStops[indexOfEditedStop].stopTitle = stopTitle;
          unscheduledStops[indexOfEditedStop].stopDescription = stopDescription;

          db.collection("Businesses")
            .doc(props.selectedBusiness)
            .collection("Jobs")
            .doc(selectedJob.jobId)
            .update({
              unscheduledStops: unscheduledStops,
            })
            .then(() => {
              setStopDescription("");
              setstopTitle("");
              setEditStopDisplay(false);
            })
            .catch((e) => console.log(e));
        });
    } else {
      db.collection("Businesses")
        .doc(props.selectedBusiness)
        .collection("Jobs")
        .doc(selectedJob.jobId)
        .get()
        .then((documentSnapshot) => {
          const data = documentSnapshot.data();
          let stops = data.stops;
          const indexOfEditedStop = stops.findIndex(
            (a) => a.stopId === stop.stopId
          );
          stops[indexOfEditedStop].name = stopTitle;
          stops[indexOfEditedStop].stopWorkerDescription = stopDescription;
          stops[indexOfEditedStop].stopCustomerDescription = stopDescription;

          db.collection("Businesses")
            .doc(props.selectedBusiness)
            .collection("Jobs")
            .doc(selectedJob.jobId)
            .update({
              stops: stops,
            })
            .then(() => {
              setStopDescription("");
              setstopTitle("");
              setEditStopDisplay(false);
            })
            .catch((e) => console.log(e));
        });
    }
  };

  const generateStopId = () => {
    let stopId = "",
      i,
      random;

    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;

      if (i === 8 || i === 12 || i === 16 || i === 20) {
        stopId += "-";
      }

      stopId += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(
        16
      );
    }

    return stopId;
  };

  const handleAddUnscheduledStopToDb = (selectedEmployee, value, newDate) => {
    // need to do dates

    const stopId = generateStopId();

    db.collection("Businesses")
      .doc(value.stopServiceLocation.businessId)
      .collection("Routes")
      .where("active", "==", true)
      .get()
      .then(async (querySnapshot) => {
        const routeId = querySnapshot.docs[0].id;

        const newStopData = {
          dayOfTheWeek: dayjs(newDate).format("dddd"),
          frequency: "once",
          startOn: dayjs(newDate).format("MM/DD/YYYY"),
          stopAfter: "no end",
          name: value.stopTitle || value.unassignedStopObject.stopTitle,
          address: value.stopServiceLocation.address,
          stopId: stopId,
          routeId: routeId,
          serviceLocationId: value.stopServiceLocation.serviceLocationId,
          completedDates: [],
          contractor: {
            id: selectedEmployee.uid,
            name: selectedEmployee.name,
            profilePhoto:
              selectedEmployee.photo || selectedEmployee.profilePhoto,
          },
          customerData: {
            name: value.unassignedStopObject
              ? value.unassignedStopObject.customer.customerName
              : value.customer.name || value.customer.customerName,
            customerId: value.unassignedStopObject
              ? value.unassignedStopObject.customer.customerId
              : value.customer.customerId,
            businessId: value.stopServiceLocation.businessId,
          },
          businessId: value.stopServiceLocation.businessId,
          stopNickname: value.stopTitle || value.unassignedStopObject.stopTitle,
          location: value.stopServiceLocationCoordinates || value.location,
          stopTemplateId: "none",
          stopCustomerDescription:
            value.stopTitle || value.unassignedStopObject.stopTitle,
          stopWorkerDescription:
            value.stopDescription || value.unassignedStopObject.stopDescription,
          stopDate: dayjs(newDate).format("MM/DD/YYYY"),
          type: "Job Stop",
          messageId: "none",
          jobId: value.jobId,
        };

        db.collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .get()
          .then((documentSnapshot) => {
            const businessData = documentSnapshot.data();

            db.collection("Businesses")
              .doc(value.stopServiceLocation.businessId)
              .collection("Jobs")
              .doc(value.jobId)
              .get()
              .then((documentSnapshot) => {
                const jobData = documentSnapshot.data();
                firebase
                  .auth()
                  .currentUser.getIdToken()
                  .then((token) => {
                    fetch(
                      "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                      {
                        method: "POST",
                        body: JSON.stringify({
                          token: token,
                          notificationData: {
                            hasApp: {
                              title: "Job stop has been scheduled!",
                              body:
                                "The stop " +
                                (value.stopTitle ||
                                  value.unassignedStopObject.stopTitle) +
                                " under the job " +
                                jobData.jobTitle +
                                " has been scheduled for " +
                                dayjs(newDate).format("MM/DD/YYYY") +
                                ".",
                            },
                            noApp: {
                              title: "Job stop been scheduled!",
                              body:
                                "The stop " +
                                value.stopTitle +
                                " under the job " +
                                jobData.jobTitle +
                                " has been scheduled. Please download the app and login into view, when the stop is scheduled for, the the stop details, and message " +
                                businessData.name +
                                " with any questions.",
                            },
                          },
                          customerId: value.unassignedStopObject
                            ? value.unassignedStopObject.customer.customerId
                            : value.customer.customerId,
                        }),
                      }
                    );
                  });
              });
          });

        console.log(newStopData);

        db.collection("Customers")
          .doc(
            value.unassignedStopObject
              ? value.unassignedStopObject.customer.customerId
              : value.customer.customerId
          )
          .collection("ServiceLocations")
          .doc(value.stopServiceLocation.serviceLocationId)
          .update({
            routes: firebase.firestore.FieldValue.arrayUnion(newStopData),
          });

        const routeData = {
          frequency: "once",
          name: value.stopTitle || value.unassignedStopObject.stopTitle,
          address: value.stopServiceLocation.address,
          stopObject: newStopData,
          routeId: stopId,
          jobId: value.jobId,
          serviceLocationId: value.stopServiceLocation.serviceLocationId,
          type: "stop",
          completed: false,
          stopId: stopId,
          contractor: selectedEmployee,
          location: value.stopServiceLocationCoordinates || value.location,
          customerData: {
            name: value.unassignedStopObject
              ? value.unassignedStopObject.customer.customerName
              : value.customer.name || value.customer.customerName,
            customerId: value.unassignedStopObject
              ? value.unassignedStopObject.customer.customerId
              : value.customer.customerId,
            businessId: value.stopServiceLocation.businessId,
          },
        };

        console.log(routeData);

        db.collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .collection("Workers")
          .doc(selectedEmployee.uid)
          .collection("Route")
          .doc("JobStops")
          .set(
            {
              route: firebase.firestore.FieldValue.arrayUnion(routeData),
              checkDistanceTime: true,
            },
            { merge: true }
          );

        await db
          .collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .collection("Workers")
          .doc(selectedEmployee.uid)
          .update({ lastUpdated: dayjs().unix() });

        db.collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .collection("Jobs")
          .doc(value.jobId)
          .update({
            stops: firebase.firestore.FieldValue.arrayRemove(value),
            unscheduledStops: firebase.firestore.FieldValue.arrayRemove(value),
          })
          .then(() => {
            db.collection("Businesses")
              .doc(value.stopServiceLocation.businessId)
              .collection("Jobs")
              .doc(value.jobId)
              .update({
                stops: firebase.firestore.FieldValue.arrayUnion(newStopData),
                status: "scheduled",
              })
              .then(() => {
                setRescheduleDisplay(false);
                setEditStopDisplay(false);
              });
          });
      });
  };

  const handleUnscheduleStop = () => {
    //editStopData
    let stopDataModified = {
      stopId: editStopData.stopId,
      stopObject: editStopData,
    };
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/unscheduleOneTimeStop",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              stop: stopDataModified,
            }),
          }
        ).then(() => {
          setEditStopDisplay(false);
          toast.success("Stop has been unscheduled!");
        });
      });
  };

  const handleRescheduleStop = () => {
    //editStopData
    if (editStopData.stopTech !== "none") {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          fetch(
            "https://us-central1-symbri-production.cloudfunctions.net/rescheduleOneTimeStop",
            {
              method: "POST",
              body: JSON.stringify({
                token: token,
                stop: editStopData,
                newDate: rescheduleDate,
                newEmployee: rescheduleEmployee,
                originalDate: dayjs(rescheduleDate).format("MM/DD/YYYY"),
              }),
            }
          );
        })
        .then(() => {
          setRescheduleDisplay(false);
          setEditStopDisplay(false);
          toast.success("Stop has been rescheduled!");
        });
    } else {
      handleAddUnscheduledStopToDb(
        rescheduleEmployee,
        editStopData,
        rescheduleDate
      );
    }
  };

  const handleReschedulePressed = () => {
    db.collection("Businesses")
      .doc(props.selectedBusiness)
      .collection("Workers")
      .get()
      .then((snapshot) => {
        let workers = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const worker = snapshot.docs[index].data();
          workers.push(worker);
        }
        setAllEmployees(workers);
        setRescheduleEmployee(workers[0]);
        setRescheduleDisplay(true);
      });
  };

  const handleDeleteStop = () => {
    //editStopData
    if (editStopData.stopTech === "none") {
      db.collection("Businesses")
        .doc(props.selectedBusiness)
        .collection("Jobs")
        .doc(editStopData.jobId)
        .update({
          stops: firebase.firestore.FieldValue.arrayRemove(editStopData),
          unscheduledStops:
            firebase.firestore.FieldValue.arrayRemove(editStopData),
        })
        .then(() => {
          setEditStopDisplay(false);
          toast.success("Stop has been deleted!");
        });
    } else {
      console.log("scheduled stop");
      toast.warn("Must first unschedule stop before it can be deleted!");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            View Job{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            You can view and edit details about this job below.
                          </p>
                        </div>
                      </div>

                      {addStopDisplay && (
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Stop Title{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setstopTitle(change.target.value)
                                    }
                                    value={stopTitle}
                                    type="text"
                                    className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Stop Description{" "}
                                </label>

                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setStopDescription(change.target.value)
                                    }
                                    value={stopDescription}
                                    type="text"
                                    className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {editStopDisplay && (
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            {!rescheduleDisplay && (
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Stop Title{" "}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      onChange={(change) =>
                                        setstopTitle(change.target.value)
                                      }
                                      value={stopTitle}
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Stop Description{" "}
                                  </label>

                                  <div className="mt-1">
                                    <input
                                      onChange={(change) =>
                                        setStopDescription(change.target.value)
                                      }
                                      value={stopDescription}
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  {editStopData.stopTech !== "none" && (
                                    <div
                                      onClick={() => handleUnscheduleStop()}
                                      className="mt-4 sm:text-sm text-blue-900 cursor-pointer"
                                    >
                                      Unschedule Stop
                                    </div>
                                  )}
                                  <div
                                    onClick={handleReschedulePressed}
                                    className="mt-4 sm:text-sm text-blue-900 cursor-pointer"
                                  >
                                    Reschedule Stop
                                  </div>

                                  {editStopData.stopTech === "none" && (
                                    <div
                                      onClick={() => handleDeleteStop()}
                                      className="mt-4 sm:text-sm text-blue-900 cursor-pointer"
                                    >
                                      Delete Stop
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {rescheduleDisplay && (
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Stop Date{" "}
                                  </label>

                                  <div className="mt-1">
                                    <input
                                      onChange={(change) =>
                                        setRescheduleDate(change.target.value)
                                      }
                                      value={rescheduleDate}
                                      type="date"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {rescheduleDisplay && (
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Employee{" "}
                                  </label>

                                  <div className="mt-1">
                                    <SelectEmployeeSearch
                                      businessId={props.businessId}
                                      setSelectedEmployee={
                                        setRescheduleEmployee
                                      }
                                      employees={allEmployees}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {addNoteDisplay && (
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Note{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setNewNoteContent(change.target.value)
                                    }
                                    value={newNoteContent}
                                    type="text"
                                    className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!addNoteDisplay &&
                        !addStopDisplay &&
                        !editStopDisplay && (
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Name{" "}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      onChange={(change) =>
                                        handleUpdateJobName(change.target.value)
                                      }
                                      value={jobName}
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Description{" "}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      value={jobDescription}
                                      onChange={(change) =>
                                        handleUpdateJobDescription(
                                          change.target.value
                                        )
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm  p-2"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    <div>Stops</div>
                                  </label>
                                  <div className="mt-1">
                                    <JobStops
                                      selectedJob={selectedJob}
                                      reloadStops={reloadStops}
                                      selectedBusiness={selectedBusiness}
                                      jobStops={jobStops}
                                      handleEditStopClick={handleEditStopClick}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Notes{" "}
                                  </label>
                                  <div className="mt-1">
                                    <JobNotes jobNotes={jobNotes} />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {" "}
                                    Photos{" "}
                                  </label>
                                  <div className="mt-1">
                                    <JobImages
                                      setMedia={setMedia}
                                      setViewImage={setViewImage}
                                      jobPhotos={jobPhotos}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {addNoteDisplay && (
                        <button
                          onClick={() => setAddNoteDisplay(false)}
                          type="submit"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>
                      )}
                      {addStopDisplay && (
                        <button
                          onClick={() => setAddStopDisplay(false)}
                          type="submit"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>
                      )}
                      {editStopDisplay && !rescheduleDisplay && (
                        <button
                          onClick={() => setEditStopDisplay(false)}
                          type="submit"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>
                      )}
                      {rescheduleDisplay && (
                        <button
                          onClick={() => setRescheduleDisplay(false)}
                          type="submit"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>
                      )}

                      {!addStopDisplay && !editStopDisplay && (
                        <button
                          style={{
                            display:
                              selectedJob && selectedJob.status === "complete"
                                ? "none"
                                : "initial",
                          }}
                          onClick={() =>
                            addNoteDisplay
                              ? handleAddNote()
                              : setAddNoteDisplay(true)
                          }
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Add Note
                        </button>
                      )}
                      {!addNoteDisplay && !editStopDisplay && (
                        <button
                          style={{
                            display:
                              selectedJob && selectedJob.status === "complete"
                                ? "none"
                                : "initial",
                          }}
                          onClick={() =>
                            addStopDisplay
                              ? handleCreateStop()
                              : setAddStopDisplay(true)
                          }
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Add Stop
                        </button>
                      )}
                      {editStopDisplay && !rescheduleDisplay && (
                        <button
                          onClick={() => handleEditStopSave(editStopData)}
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Update
                        </button>
                      )}
                      {rescheduleDisplay && (
                        <button
                          onClick={handleRescheduleStop}
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Reschedule
                        </button>
                      )}
                      {!addNoteDisplay &&
                        !addStopDisplay &&
                        !editStopDisplay && (
                          <button
                            style={{
                              display:
                                selectedJob && selectedJob.status === "complete"
                                  ? "none"
                                  : "initial",
                            }}
                            onClick={handleCompleteJob}
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Complete
                          </button>
                        )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          <ViewImage media={media} open={viewImage} setOpen={setViewImage} />
        </div>
      </Dialog>
    </Transition.Root>
  );
}
