import { useEffect, useState } from "react";
import SubscriptionDetailsTabs from "./SubscriptionDetailsTabs";
import firebase from "firebase/compat/app";

export default function SubscriptionDetails(props) {
  const db = firebase.firestore();
  const subscription = props.subscription;
  const [selectedTab, setSelectedTab] = useState("Customers");
  const [customers, setCustomers] = useState([]);

  const stats = [
    {
      name: "Monthly Earnings",
      stat: "$" + customers.length * subscription.subscriptionPrice,
    },
    { name: "Total Customers", stat: customers.length },
    { name: "Avg. Stop Time", stat: "8 Minutes" },
  ];

  useEffect(() => {
    db.collection("Customers")
      .where("businessIds", "array-contains", props.selectedBusiness)
      .get()
      .then((querySnapshot) => {
        let customersArray = [];
        for (let index = 0; index < querySnapshot.docs.length; index++) {
          const customerDoc = querySnapshot.docs[index].data();

          db.collection("Customers")
            .doc(customerDoc.customerId)
            .collection("ServiceLocations")
            .where("businessId", "==", props.selectedBusiness)
            .get()
            .then((querySnapshot) => {
              for (let index = 0; index < querySnapshot.docs.length; index++) {
                const serviceLocationDoc = querySnapshot.docs[index].data();

                if (
                  serviceLocationDoc.subscriptions.subscriptionId ===
                  subscription.subscriptionId
                ) {
                  customersArray.push({
                    serviceLocationId: serviceLocationDoc.serviceLocationId,
                    serviceLocationOwnerName:
                      customerDoc.customerFirstName +
                      " " +
                      customerDoc.customerLastName,
                    serviceLocationAddress: serviceLocationDoc.address,
                    serviceLocationName: serviceLocationDoc.name,
                    subscription: serviceLocationDoc.subscriptions,
                  });
                }
              }
            });
        }
        setTimeout(() => {
          setCustomers(customersArray);
        }, 500);
      });
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Customers Enrolled: {customers.length}
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <SubscriptionDetailsTabs setSelectedTab={setSelectedTab} />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedTab === "Metrics" && (
                <div style={{ padding: 20, backgroundColor: "#F9FAFB" }}>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Last 30 days
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {stats.map((item) => (
                      <div
                        key={item.name}
                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                      >
                        <dt className="truncate text-sm font-medium text-gray-500">
                          {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                          {item.stat}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              )}

              {selectedTab === "Customers" && (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Owner
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Cost
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {customers.map((customer) => (
                      <tr key={customer.serviceLocationId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {customer.serviceLocationOwnerName.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {customer.serviceLocationAddress.substring(0, 30) +
                            "..."}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          ${customer.subscription.subscriptionPrice}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
