import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import logo from "../Assets/symbrilogo.png";
import SelectPayType from "./SelectPayType";
import { getMediaStream } from "../Tools/getMediaStream";
import { Rings } from "react-loader-spinner";

export default function EmployeeAdd(props) {
  const [open, setOpen] = useState(false);
  const [workerEmail, setWorkerEmail] = useState("");
  const [workerName, setWorkerName] = useState("");
  const [workerPayRate, setWorkerPayRate] = useState("");
  const [employeePayMethod, setEmployeePayMethod] = useState("unselected");
  const [employeePhoto, setEmployeePhoto] = useState(logo);
  const [workerPhone, setWorkerPhone] = useState("");
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const [employeePhotoFile, setEmployeePhotoFile] = useState();
  const db = firebase.firestore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const validatePhoneNumber = (input_str) => {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(input_str);
  };

  const handleCreateWorker = () => {
    if (workerName === "" || workerName.trim().length === 0) {
      toast.warn("Employee name field is required!");
      return;
    } else if (workerEmail === "" || workerEmail.trim().length === 0) {
      toast.warn("Employee email field is required!");
      return;
    } else if (workerPayRate === "" || workerPayRate.trim().length === 0) {
      toast.warn("Employee pay rate field is required!");
      return;
    } else if (workerPhone === "" || workerPhone.trim().length === 0) {
      // need to also validate phone number
      toast.warn("Employee phone field is required!");
      return;
    } else if (employeePayMethod === "unselected") {
      toast.warn("Employee pay type field is required!");
      return;
    } else if (!validatePhoneNumber(workerPhone)) {
      toast.warn("Employee phone field is invalid format!");
      return;
    }
    setLoading(true);
    const generatedUserPassword = Math.random().toString(36).slice(-8);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/createUser",
          {
            method: "POST",
            body: JSON.stringify({
              email: workerEmail,
              pass: generatedUserPassword,
              token: token,
            }),
          }
        )
          .then((response) => response.json())
          .then(async (userCredential) => {
            if (!userCredential.error) {
              const user = userCredential.user;

              const imageRef = storageRef.child(
                props.businessId + "/employeeProfilePhotos/" + user.uid
              );
              await imageRef.put(employeePhotoFile);
              console.log("Uploaded a blob or file!");
              const url = await getMediaStream(
                props.businessId + "/employeeProfilePhotos/" + user.uid
              );
              db.collection("Businesses")
                .doc(props.businessId)
                .collection("Workers")
                .doc(user.uid)
                .set({
                  name: workerName.toLowerCase(),
                  email: workerEmail,
                  uid: user.uid,
                  phone: workerPhone,
                  businessId: props.businessId,
                  poolsCleanedThisMonth: 0,
                  threadIds: [],
                  payRate: workerPayRate,
                  payMethod: employeePayMethod,
                  unpaidEarnings: 0,
                  uninvoicedEarnings: 0,
                  totalEarnings: 0,
                  feedbackRatings: [],
                  ratingScore: 0,
                  connections: [],
                  pending: true,
                  profilePhoto: employeePhotoFile
                    ? url
                    : "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef",
                  department: {},
                })
                .then(() => {
                  db.collection("Businesses")
                    .doc(props.businessId)
                    .collection("Workers")
                    .doc(user.uid)
                    .collection("PoolsCleanedPayPeriod")
                    .doc("Pools")
                    .set({
                      poolsCleanedThisMonthData: [],
                    })
                    .then(() => {
                      db.collection("Users")
                        .doc(user.uid)
                        .set({
                          name: workerName,
                          email: workerEmail,
                          uid: user.uid,
                          businessId: props.businessId,
                          userType: "worker",
                          threadIds: [],
                        })
                        .then(() => {
                          firebase
                            .auth()
                            .currentUser.getIdToken()
                            .then((token) => {
                              const value = {
                                name: workerName.toLowerCase(),
                                email: workerEmail,
                                uid: user.uid,
                                phone: workerPhone,
                                businessId: props.businessId,
                                poolsCleanedThisMonth: 0,
                                threadIds: [],
                                payRate: workerPayRate,
                                payMethod: employeePayMethod,
                                unpaidEarnings: 0,
                                uninvoicedEarnings: 0,
                                totalEarnings: 0,
                                feedbackRatings: [],
                                ratingScore: 0,
                                connections: [],
                                pending: true,
                                profilePhoto: url,
                                department: {},
                              };

                              // Get the route id of all routes and add to it

                              // Need to also add all current employees when a new route is created dont forget

                              db.collection("Businesses")
                                .doc(props.businessId)
                                .collection("Routes")
                                .get()
                                .then((routesQuery) => {
                                  const routesDocs = routesQuery.docs;

                                  for (
                                    let index = 0;
                                    index < routesDocs.length;
                                    index++
                                  ) {
                                    const route = routesDocs[index].data();

                                    db.collection("Businesses")
                                      .doc(props.businessId)
                                      .collection("Routes")
                                      .doc(route.routeId)
                                      .collection("Employees")
                                      .doc(value.uid)
                                      .set({
                                        name: value.name,
                                        uid: value.uid,
                                        businessId: props.businessId,
                                        photo: value.profilePhoto,
                                        mon: true,
                                        tue: true,
                                        wed: true,
                                        thu: true,
                                        fri: true,
                                        sat: true,
                                        sun: true,
                                      })
                                      .then(() => {
                                        db.collection("Businesses")
                                          .doc(props.businessId)
                                          .collection("Routes")
                                          .doc(route.routeId)
                                          .collection("Employees")
                                          .doc(value.uid)
                                          .collection("Routes")
                                          .doc("Pending")
                                          .set({
                                            pending: true,
                                          });
                                      });
                                  }

                                  setTimeout(() => {
                                    fetch(
                                      "https://us-central1-symbri-production.cloudfunctions.net/sendWorkerGreeting",
                                      {
                                        method: "POST",
                                        body: JSON.stringify({
                                          workerEmail: workerEmail,
                                          workerName: workerName,
                                          workerPass: generatedUserPassword,
                                          workerUid: user.uid,
                                          token: token,
                                        }),
                                      }
                                    ).then(() => {
                                      setLoading(false);
                                      handleClose();

                                      toast.success(
                                        "Worker created! Their password has been emailed to them!"
                                      );
                                      setEmployeePhoto(logo);
                                    });
                                  }, 1000);
                                });
                            });
                        });
                    });
                });
            } else {
              console.log(userCredential.error);
              toast.warn(userCredential.error.message);
            }
          })
          .catch((error) => {
            const errorMessage = error.message;
            toast.warn(errorMessage.message);
          });
      });
  };

  const imageHandler = (event) => {
    const file = event.target.files[0];
    if (file.type.includes("image")) {
      setEmployeePhotoFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setEmployeePhoto(reader.result);
        console.log(reader.result);
      };
      reader.onerror = () => {
        console.log("there are some problems");
      };
    } else {
      alert("Only images allowed!");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            New Employee{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            add a new employee.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Name{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setWorkerName(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Email{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setWorkerEmail(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Phone{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setWorkerPhone(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Pay Type{" "}
                              </label>
                              <div className="mt-1">
                                <SelectPayType
                                  setEmployeePayMethod={setEmployeePayMethod}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Pay Rate{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setWorkerPayRate(change.target.value)
                                  }
                                  type="number"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Profile Photo{" "}
                              </label>
                              <div className="mt-1">
                                <label
                                  style={{
                                    width: 200,
                                    height: 200,
                                    border: "1px dotted black",
                                    borderRadius: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  {employeePhoto === logo && (
                                    <div>Add Profile Photo</div>
                                  )}
                                  {employeePhoto !== logo && (
                                    <img
                                      src={employeePhoto}
                                      style={{
                                        width: "100%",
                                        objectFit: "cover",
                                        height: "100%",
                                      }}
                                    />
                                  )}
                                  <input type="file" onChange={imageHandler} />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "88%", marginTop: 12 }}>
                          Pursuant to the regulations set forth by the Telephone
                          Consumer Protection Act (TCPA), it is imperative that
                          written consent is obtained from your employee before
                          engaging in any form of text messaging. By clicking
                          the import button below, you are explicitly
                          acknowledging and affirming your utilization of the
                          Symbri software for the purpose of transmitting text
                          messages to your employees, while affirming further
                          that you possess the requisite prior consent to engage
                          in such textual communication.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleCreateWorker}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Loading </div>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              arialLabel="loading-indicator"
                            />
                          </div>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
