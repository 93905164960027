import { useState } from "react";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import logo from "../Assets/symbrilogo.png";
import UpdateName from "./UpdateName";
import UpdateEmail from "./UpdateEmail";
import UpdateLogo from "./UpdateLogo";
import UpdatePassword from "./UpdatePassword";
import UpdateServiceZipzode from "./UpdateServiceZipcode";
import UpdateOwnerName from "./UpdateOwnerName";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import UpdateUserPassword from "./UpdateUserPassword";
import UpdateUserEmail from "./UpdateUserEmail";
import UpdateUserPhone from "./UpdateUserPhone";
import UpdateUserName from "./UpdateUserName";
import UpdateSupportPhone from "./UpdateSupportPhone";
import UpdateCustomerServiceEmployee from "./UpdateCustomerServiceEmployee";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [{ name: "General", href: "#", current: true }];

export default function Settings(props) {
  const [businessData, setBusinessData] = useState({});
  const [updateBusinessNameDisplay, setUpdateBusinessNameDisplay] =
    useState(false);
  const [updateBusinessLogoDisplay, setUpdateBusinessLogoDisplay] =
    useState(false);
  const [updateBusinessOwnerNameDisplay, setUpdateBusinessOwnerNameDisplay] =
    useState(false);
  const [updateBusinessEmailDisplay, setUpdateBusinessEmailDisplay] =
    useState(false);
  const [updateBusinessPasswordDisplay, setUpdateBusinessPasswordDisplay] =
    useState(false);
  const [
    updateBusinessServiceZipcodeDisplay,
    setUpdateBusinessServiceZipcodeDisplay,
  ] = useState(false);
  const [userData, setUserData] = useState({});
  const [isBusinessAccount, setIsBusinessAccount] = useState(false);
  const [updateUserNameDisplay, setUpdateUserNameDisplay] = useState(false);
  const [updateUserEmailDisplay, setUpdateUserEmailDisplay] = useState(false);
  const [updateUserPasswordDisplay, setUpdateUserPasswordDisplay] =
    useState(false);
  const [updateUserPhoneDisplay, setUpdateUserPhoneDisplay] = useState(false);
  const [updateBusinessSupportPhone, setUpdateBusinessSupportPhone] =
    useState(false);
  const [updateCustomerServiceEmployee, setUpdateCustomerServiceEmployee] =
    useState(false);

  const [selectedCustomerServiceEmployee, setSelectedCustomerServiceEmployee] =
    useState();

  const db = firebase.firestore();

  useEffect(() => {
    db.collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((userDocumentSnashot) => {
        const data = userDocumentSnashot.data();
        if (data.userType === "owner" || data.userType === "business") {
          setIsBusinessAccount(true);

          db.collection("Businesses")
            .doc(props.businessId)
            .onSnapshot((documentSnapshot) => {
              const data = documentSnapshot.data();
              setBusinessData(data);

              db.collection("Businesses")
                .doc(props.businessId)
                .collection("Workers")
                .doc(data.customerServiceEmployee)
                .get()
                .then((documentSnapshot) => {
                  const data = documentSnapshot.data();
                  setSelectedCustomerServiceEmployee(data);
                });
            });
        } else {
          db.collection("Businesses")
            .doc(props.businessId)
            .collection("Workers")
            .doc(firebase.auth().currentUser.uid)
            .get()
            .then((workerDocumentSnapshot) => {
              const workerData = workerDocumentSnapshot.data();
              setUserData(workerData);
            });
        }
      });
  }, []);

  const handleWipeBusiness = () => {
    confirmAlert({
      title: "Please confirm!",
      message:
        "Are you sure you want to wipe this business? This cannot be reversed. All your data will be gone forever, please think this over for a minute before resuming.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firebase
              .auth()
              .currentUser.getIdToken()
              .then((token) => {
                fetch(
                  "https://us-central1-symbri-production.cloudfunctions.net/wipeBusiness",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      businessId: props.businessId,
                      token: token,
                    }),
                  }
                )
                  .then((json) => json.json())
                  .then((res) => {
                    console.log(res);
                    toast.success(
                      "Your business will be wiped. This process may take a few minutes. You will be logged out in 10 seconds."
                    );

                    setTimeout(() => {
                      window.location.reload();
                    }, 10000);
                  });
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  return (
    <>
      <div className="min-w-full">
        <div className="mx-auto flex min-w-full flex-col md:px-8 xl:px-8">
          <main className="flex-1">
            <div className="relative mx-auto min-w-full md:px-8 xl:px-8">
              <div className="px-4 sm:px-6 md:px-0">
                <div className="py-6">
                  {/* Tabs */}
                  <div className="lg:hidden">
                    <label htmlFor="selected-tab" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="selected-tab"
                      name="selected-tab"
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={tabs.find((tab) => tab.current).name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden lg:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                  {/* If employee show these settings*/}
                  {/* Temp disabled see trello board*/}
                  {!isBusinessAccount && (
                    <div className="mt-10 divide-y divide-gray-200">
                      <div className="space-y-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          User Profile
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          You can update and view general information about your
                          account here!
                        </p>
                      </div>
                      <div className="mt-6">
                        <dl className="divide-y divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Name
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">{userData.name}</span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() => setUpdateUserNameDisplay(true)}
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Email
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {userData.email}
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateUserEmailDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Phone Number
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {userData.phone}
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateUserPhoneDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Password
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                ******************
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateUserPasswordDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  )}
                  {/* If business owner show these settings*/}
                  {isBusinessAccount && (
                    <div className="mt-10 divide-y divide-gray-200">
                      <div className="space-y-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Business Profile
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          You can update and view general information about your
                          business here!
                        </p>
                      </div>
                      <div className="mt-6">
                        <dl className="divide-y divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Business Name
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {businessData.name}
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateBusinessNameDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Logo
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={
                                    businessData.logo ? businessData.logo : logo
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                                <div
                                  onClick={() =>
                                    setUpdateBusinessLogoDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Owner Name
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {businessData.ownerName}
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateBusinessOwnerNameDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Email
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {businessData.email}
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateBusinessEmailDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Password
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                ******************
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateBusinessPasswordDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Service Zipcode
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {businessData.serviceAreaZipcode}
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateBusinessServiceZipcodeDisplay(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Customer Support Phone
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <span className="flex-grow">
                                {businessData.supportPhone}
                              </span>
                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateBusinessSupportPhone(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Customer Service Employee
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedCustomerServiceEmployee && (
                                <span className="flex-grow">
                                  {selectedCustomerServiceEmployee.name}
                                </span>
                              )}

                              <span className="ml-4 flex-shrink-0">
                                <div
                                  onClick={() =>
                                    setUpdateCustomerServiceEmployee(true)
                                  }
                                  type="div"
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 cursor-pointer"
                                >
                                  Update
                                </div>
                              </span>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <UpdateName
        open={updateBusinessNameDisplay}
        businessId={props.businessId}
        setOpen={setUpdateBusinessNameDisplay}
        businessName={props.businessName}
        setBusinessName={props.setBusinessName}
      />

      <UpdateOwnerName
        open={updateBusinessOwnerNameDisplay}
        businessId={props.businessId}
        setOpen={setUpdateBusinessOwnerNameDisplay}
        businessOwnerName={businessData.ownerName}
      />
      <UpdateLogo
        businessId={props.businessId}
        open={updateBusinessLogoDisplay}
        setOpen={setUpdateBusinessLogoDisplay}
      />
      <UpdateEmail
        open={updateBusinessEmailDisplay}
        setOpen={setUpdateBusinessEmailDisplay}
      />
      <UpdatePassword
        open={updateBusinessPasswordDisplay}
        setOpen={setUpdateBusinessPasswordDisplay}
      />
      <UpdateServiceZipzode
        businessId={props.businessId}
        open={updateBusinessServiceZipcodeDisplay}
        setOpen={setUpdateBusinessServiceZipcodeDisplay}
      />

      <UpdateUserPassword
        open={updateUserPasswordDisplay}
        setOpen={setUpdateUserPasswordDisplay}
        businessId={props.businessId}
      />

      <UpdateUserEmail
        email={userData.email}
        open={updateUserEmailDisplay}
        setOpen={setUpdateUserEmailDisplay}
        businessId={props.businessId}
      />

      <UpdateUserPhone
        phone={userData.phone}
        open={updateUserPhoneDisplay}
        setOpen={setUpdateUserPhoneDisplay}
        businessId={props.businessId}
      />

      <UpdateUserName
        name={userData.name}
        open={updateUserNameDisplay}
        setOpen={setUpdateUserNameDisplay}
        businessId={props.businessId}
      />

      <UpdateSupportPhone
        name={userData.name}
        open={updateBusinessSupportPhone}
        setOpen={setUpdateBusinessSupportPhone}
        businessId={props.businessId}
        businessData={businessData}
      />

      <UpdateCustomerServiceEmployee
        name={userData.name}
        open={updateCustomerServiceEmployee}
        setOpen={setUpdateCustomerServiceEmployee}
        businessId={props.businessId}
        businessData={businessData}
      />
    </>
  );
}
