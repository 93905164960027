import "./App.css";
import AuthManager from "./AuthManager";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

firebase.initializeApp({
  apiKey: "AIzaSyD70kJKBJl83GEAa5wP16UdE-nmnKJNC4E",
  authDomain: "symbri-production.firebaseapp.com",
  projectId: "symbri-production",
  storageBucket: "symbri-production.appspot.com",
  messagingSenderId: "73831026776",
  appId: "1:73831026776:web:eab083740cb6995a927a2d",
  measurementId: "G-VN7G752GWP",
});

function App() {
  return (
    <div>
      <AuthManager />
      <ToastContainer autoClose={12000} />
    </div>
  );
}

export default App;
