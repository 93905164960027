import firebase from "firebase/compat/app";

export const getProfilePhoto = async (employee) => {
  const db = firebase.firestore();
  return db
    .collection("Businesses")
    .doc(employee.businessId)
    .collection("Workers")
    .doc(employee.uid)
    .get()
    .then((documentSnapshot) => {
      const data = documentSnapshot.data();
      return data.profilePhoto;
    })
    .catch((e) => console.log(e));
};
