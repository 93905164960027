/* This example requires Tailwind CSS v2.0+ */

import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import { ImProfile } from "react-icons/im";
import ImportEmployees from "./ImportEmployees";
import EmployeesButton from "./EmployeesButton";
import EmployeeAdd from "./EmployeeAdd";
import EmployeeProfile from "./EmployeeProfile";

export default function Employees(props) {
  const selectedBusiness = props.businessId;
  const [allWorkers, setAllWorkers] = useState([]);
  const [addEmployeeDisplay, setAddEmployeeDisplay] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();

  const [importEmployeesDisplay, setImportEmployeesDisplay] = useState(false);
  const [firstVisibleStack, setFirstVisibleStack] = useState([]);
  const [lastVisibleStack, setLastVisibleStack] = useState([]);
  const [backEnabled, setBackEnabled] = useState(false);
  const isMounted = useRef();
  const [disablePagination, setDisablePagination] = useState(false);
  const db = firebase.firestore();

  let initialWorkerListener = null;

  const getWorkers = (lastVisible) => {
    let query = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .orderBy("name")
      .limit(20);

    if (lastVisible) {
      query = query.startAfter(lastVisible);
    } else {
      initialWorkerListener = query.onSnapshot((snapshot) => {
        const workers = snapshot.docs.map((doc) => doc.data());

        setAllWorkers(workers);

        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        setLastVisibleStack((prevStack) => [...prevStack, lastVisible]);
      });
    }

    return query.get();
  };

  const stopInitialWorkerListener = () => {
    if (initialWorkerListener) {
      initialWorkerListener();
      initialWorkerListener = null;
    }
  };

  useEffect(() => {
    isMounted.current = true;

    const handleAsync = async () => {
      if (isMounted.current) {
        await getWorkers(null);
      }
    };

    handleAsync();

    return () => {
      isMounted.current = false;
      stopInitialWorkerListener();
    };
  }, [selectedBusiness]);

  const handleNext = async () => {
    const lastVisibleState = lastVisibleStack[lastVisibleStack.length - 1];
    const snapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .orderBy("name")
      .startAfter(lastVisibleState)
      .limit(20)
      .get();

    // Only proceed if there are more documents
    if (snapshot.empty) {
      return;
    }

    const workers = snapshot.docs.map((doc) => doc.data());

    // Save the first and last documents of the current page
    const firstVisible = snapshot.docs[0];
    const lastVisibleSnapshot = snapshot.docs[snapshot.docs.length - 1];

    setAllWorkers(workers);

    // Push the current first and lastVisible to the stacks
    setFirstVisibleStack((prevStack) => [...prevStack, firstVisible]);
    setLastVisibleStack((prevStack) => [...prevStack, lastVisibleSnapshot]);

    // Enable the back button because we've navigated forward
    setBackEnabled(true);
  };

  const handleBack = async () => {
    // Pop the last elements from the stacks
    const newFirstVisibleStack = [...firstVisibleStack];
    const previousFirstVisible = newFirstVisibleStack.pop();
    setFirstVisibleStack(newFirstVisibleStack);

    const newLastVisibleStack = [...lastVisibleStack];
    newLastVisibleStack.pop();
    setLastVisibleStack(newLastVisibleStack);

    // If there are any previous pages left, get the first document of the previous page
    if (previousFirstVisible) {
      const snapshot = await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Workers")
        .orderBy("name")
        .startAfter(previousFirstVisible)
        .limit(20)
        .get();

      const workers = snapshot.docs.map((doc) => doc.data());

      setAllWorkers(workers);
    }

    // Disable the back button if we're on the first page
    if (newFirstVisibleStack.length === 0) {
      setBackEnabled(false);
    }
  };

  const handleSearch = async (term) => {
    if (term !== "") {
      //here
      setDisablePagination(true);
      const snapshot = await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Workers")
        .where("name", ">=", term)
        .orderBy("name")
        .limit(20)
        .get();

      const workers = snapshot.docs.map((doc) => doc.data());

      setAllWorkers(workers);
    } else {
      const snapshot = await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Workers")
        .orderBy("name")
        .limit(20)
        .get();

      const workers = snapshot.docs.map((doc) => doc.data());

      // Save the last document of the current page
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];

      setAllWorkers(workers);

      // Push the current lastVisible to the stack
      setLastVisibleStack((prevStack) => [...prevStack, lastVisible]);

      setDisablePagination(false);
    }
  };

  useEffect(() => {
    handleSearch(props.searchTerm);
  }, [props.searchTerm]);

  const handleButtonClick = (key) => {
    if (key === "New Employee") {
      setAddEmployeeDisplay(true);
    } else {
      setImportEmployeesDisplay(true);
    }
  };

  const handleViewEmployee = (employeeData) => {
    setSelectedEmployee(employeeData);
    props.handleAddToBreadcrumb({
      name: employeeData.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
      ),
      type: "employee",
      current: true,
    });
  };

  return !selectedEmployee ? (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Employees</h1>
          <p className="mt-2 text-sm text-gray-700"></p>
        </div>

        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <EmployeesButton
            handleButtonClick={handleButtonClick}
            businessId={selectedBusiness}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          onClick={handleBack}
                          disabled={!backEnabled || disablePagination}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-4"
                        >
                          Back
                        </button>
                        <button
                          disabled={disablePagination}
                          onClick={handleNext}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                          Next
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {allWorkers
                    .filter((a) => a.userType !== "business")
                    .map((person) => (
                      <tr key={person.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <img
                                src={person.profilePhoto}
                                className="inline-block h-10 w-10 rounded-full"
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {person.name.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </div>
                              <div className="text-gray-500">
                                {person.userType}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          onClick={() => handleViewEmployee(person)}
                          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                        >
                          <ImProfile
                            size={20}
                            className="ml-auto cursor-pointer mr-8"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <EmployeeAdd
        open={addEmployeeDisplay}
        setOpen={setAddEmployeeDisplay}
        businessId={selectedBusiness}
      />
      <ImportEmployees
        businessId={selectedBusiness}
        open={importEmployeesDisplay}
        setOpen={setImportEmployeesDisplay}
      />
    </div>
  ) : (
    <EmployeeProfile selectedEmployeeData={selectedEmployee} />
  );
}
