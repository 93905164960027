import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { RiPlayListAddFill } from "react-icons/ri";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";

const DayOfTheWeekCard = (props) => {
  const db = firebase.firestore();
  const employeeData = props.employeeData;
  const selectedBusiness = props.selectedBusiness;
  const selectedRoute = props.selectedRoute;
  const [visible, setVisible] = useState(true);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [count, setCount] = useState(0);
  const [dayData, setDayData] = useState([]);

  useEffect(() => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .doc(employeeData.uid)
      .collection("Routes")
      .doc(props.day)
      .onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const data = documentSnapshot.data();
          setDayData(data.route);
          setCount(data.route.length);
        }
      });
  }, []);

  useEffect(() => {
    if (props.day === props.editModeDay) {
      setEditModeEnabled(true);
      if (!visible) {
        handleDayVisibleToggle();
      }
    } else {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("Employees")
        .doc(employeeData.uid)
        .collection("Routes")
        .doc(props.day)
        .get()
        .then((documentSnapshot) => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            setDayData(data.route);
            setCount(data.route.length);
          }
        });
      setEditModeEnabled(false);
    }
  }, [props.editModeDay]);

  const handleEditModeClicked = (day) => {
    if (editModeEnabled) {
      props.handleCancelEditMode();
    } else {
      props.handleEditMode(day, props.employeeData);
    }
    setEditModeEnabled(!editModeEnabled);
  };

  const handleDayVisibleToggle = () => {
    if (visible) {
      setVisible(false);
      props.handleToggleHiddenDay(props.day, false, employeeData.uid);
    } else {
      setVisible(true);
      props.handleToggleHiddenDay(props.day, true, employeeData.uid);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "90%",
      }}
    >
      <div
        style={{
          backgroundColor: props.color,
          height: "22px",
          minWidth: "22px",
          color: "white",
          display: "flex",
          justifyContent: "center",
          margin: 6,
          alignItems: "center",
          border: "1px solid black",
          borderRadius: "0.5rem",
          fontSize: "0.8rem",
        }}
      >
        {count}
      </div>
      <div
        onClick={() =>
          props.handleViewDay({
            dayData: dayData,
            employeeData: employeeData,
            day: props.day,
            color: props.color,
            count: count,
          })
        }
        style={{
          margin: 7,
          width: "10px",
          textAlign: "left",
          cursor: "pointer",
          fontSize: "0.8rem",
        }}
      >
        {props.day}
      </div>
      <div
        onClick={() => handleDayVisibleToggle()}
        style={{ margin: 4, marginLeft: "40%", cursor: "pointer" }}
      >
        {visible ? (
          <AiFillEye color="#00A6FF" size={24} />
        ) : (
          <AiOutlineEye color="#00A6FF" size={24} />
        )}
      </div>
      <div
        onClick={() => handleEditModeClicked(props.day)}
        style={{ margin: 4, marginLeft: "6.4%", cursor: "pointer" }}
      >
        <RiPlayListAddFill
          size={22}
          color={editModeEnabled ? "#00A6FF" : "gray"}
        />
      </div>
    </div>
  );
};

export default DayOfTheWeekCard;
