import React, { useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import dayjs from "dayjs";
const weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

const ChangeCard = (props) => {
  const change = props.changesData;
  // Define your state variables here

  const findNextOccurrence = (date, dayOfWeek) => {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const targetDayIndex = dayNames.indexOf(dayOfWeek);

    if (targetDayIndex === -1) {
      return "Invalid day of the week.";
    }

    let currentDate = dayjs(date);
    let currentDayIndex = currentDate.day();

    let daysToAdd = targetDayIndex - currentDayIndex;

    if (daysToAdd <= 0) {
      daysToAdd += 7;
    }

    return currentDate.add(daysToAdd, "day").format("MM/DD/YYYY");
  };

  const handleChangeStartDate = (date) => {
    console.log(date);
    props.setStartDate(date);

    let currentChanges = props.changesData;

    const indexOfChange = currentChanges.findIndex(
      (a) => a.changeId === change.changeId
    );

    currentChanges[indexOfChange].startOn = dayjs(date).format("MM/DD/YYYY");

    props.setChangesData(currentChanges);
  };

  return (
    <div className="border-gray-800 border h-64">
      <div className="border-gray-800 border h-[70px] m-2 pt-1">
        <div className="flex w-full">
          <div className="ml-2 text-sm">{change.name}</div>
          <div className="ml-auto mr-2 text-sm">{change.employeeName}</div>
        </div>

        <div className="flex w-full">
          <div className="ml-2 text-sm">
            {change.recurringStopType.stopName}
          </div>
          <div className="ml-auto mr-2 text-sm">
            {change.recurringStopType.recurringSchedule}
          </div>
        </div>

        <div className="flex w-full">
          <div className="ml-2 text-sm">
            Next Service Date: {findNextOccurrence(new Date(), change.day)}
          </div>
          <div className="ml-auto mr-2 text-sm">{change.day}</div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FaArrowDown />
      </div>

      {!props.moveDay && (
        <div className="m-2 mt-4 border-gray-800 border flex flex-col">
          <div className="text-[10px] w-full m-1 z-[1] ml-2">Move To Day:</div>
          <select
            value={props.moveDay}
            onChange={(change) => props.setMoveDay(change.target.value)}
            style={{ marginTop: -10 }}
            className="changesPlannerDatePicker block w-[94%] sm:text-sm resize-none h-8 ml-2 outline-none"
          >
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
        </div>
      )}

      {props.moveDay && (
        <div className="border-gray-800 border h-[70px] m-2 pt-1">
          <div className="flex w-full">
            <div className="ml-2 text-sm">{change.name}</div>
            <div className="ml-auto mr-2 text-sm">{change.employeeName}</div>
          </div>

          <div className="flex w-full">
            <div className="ml-2 text-sm">
              {change.recurringStopType.stopName}
            </div>
            <div className="ml-auto mr-2 text-sm">
              {change.recurringStopType.recurringSchedule}
            </div>
          </div>

          <div className="flex w-full">
            <div className="ml-2 text-sm">
              Next Service Date: {findNextOccurrence(new Date(), props.moveDay)}
            </div>
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => props.setMoveDay()}
              className="ml-auto mr-2 text-sm"
            >
              {props.moveDay}
            </div>
          </div>
        </div>
      )}
      {props.moveDay && (
        <div className="m-2 mt-4 border-gray-800 border flex flex-col">
          <div className="text-[10px] w-full m-1 z-[1] ml-2">
            Apply changes on:
          </div>
          <input
            value={props.startDate}
            onChange={(change) => props.setStartDate(change.target.value)}
            type="date"
            style={{ marginTop: -10 }}
            className="changesPlannerDatePicker block w-[94%] sm:text-sm resize-none h-8 ml-2 outline-none"
          />
        </div>
      )}
    </div>
  );
};

export default ChangeCard;
