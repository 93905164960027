import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { Rings } from "react-loader-spinner";
import ChangeCard from "./ChangeCard";
import dayjs from "dayjs";
import { generateBusinessId } from "../../Tools/generateBusinessId";
import { toast } from "react-toastify";

export default function ChangePlanner(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [selectedEmployee, setSelectedEmployee] = useState();
  const routeIsAlreadyActive = props.routeIsAlreadyActive;
  const [moveDay, setMoveDay] = useState("Monday");
  const [startDate, setStartDate] = useState();
  const db = firebase.firestore();

  function isNotPreviousDate(dateString) {
    const inputDate = dayjs(dateString);
    const today = dayjs();

    if (inputDate.isBefore(today, "day")) {
      return false;
    }

    return true;
  }

  const handleSaveChange = async () => {
    // need to create the change and add to db.
    const changeId = generateBusinessId();
    props.makingChange.current = true;
    if (selectedEmployee && moveDay && startDate) {
      const prevDate = isNotPreviousDate(startDate);

      if (!prevDate) {
        toast.warn("Cannot choose date earlier than today!");
        return;
      }

      const emp = JSON.parse(selectedEmployee);

      let to = JSON.parse(JSON.stringify(props.moveData));

      to.day = moveDay;

      to.edited = true;

      to.employeeName = emp.name;

      to.employeePhoto = emp.photo;

      to.employeeUid = emp.uid;

      const change = {
        from: props.moveData,
        to: to,
        changeId: changeId,
        serviceLocationId: props.moveData.serviceLocationId,
        stopId: props.moveData.stopId,
        startOn: dayjs(startDate).format("MM/DD/YYYY"),
      };

      console.log(change);

      // check to make sure date is in the future or today

      // make default date today

      // check to see if a change for this stop id already exists, if it does replace it.

      await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .update({ changes: firebase.firestore.FieldValue.arrayUnion(change) });

      setTimeout(() => {
        props.makingChange.current = false;
      }, 1000);

      props.setOpen(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    setMoveDay("Monday");
    setOpen(props.open);
    if (props.moveData) {
      console.log(props.moveData);
      console.log(props.employeesInRoute);
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            Change Planner{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Plan the changes in your route by completing the
                            form below!
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div className="m-2 mt-4 border-gray-800 border flex flex-col">
                              <div className="text-[10px] w-full m-1 z-[1] ml-2">
                                Employee:
                              </div>
                              <select
                                value={props.moveDay}
                                onChange={(change) =>
                                  setSelectedEmployee(change.target.value)
                                }
                                style={{ marginTop: -10 }}
                                className="changesPlannerDatePicker block w-[94%] sm:text-sm resize-none h-8 ml-1 outline-none"
                              >
                                <option value="unselected">
                                  Select Employee
                                </option>
                                {props.employeesInRoute.map((employee) => (
                                  <option value={JSON.stringify(employee)}>
                                    {employee.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="m-2 mt-4 border-gray-800 border flex flex-col">
                              <div className="text-[10px] w-full m-1 z-[1] ml-2">
                                Move To Day:
                              </div>
                              <select
                                value={moveDay}
                                onChange={(change) =>
                                  setMoveDay(change.target.value)
                                }
                                style={{ marginTop: -10 }}
                                className="changesPlannerDatePicker block w-[94%] sm:text-sm resize-none h-8 ml-1 outline-none"
                              >
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                              </select>
                            </div>
                            <div className="m-2 mt-4 border-gray-800 border flex flex-col">
                              <div className="text-[10px] w-full m-1 z-[1] ml-2">
                                Apply changes on:
                              </div>
                              <input
                                value={startDate}
                                onChange={(change) =>
                                  setStartDate(change.target.value)
                                }
                                type="date"
                                style={{ marginTop: -10 }}
                                className="changesPlannerDatePicker block w-[94%] sm:text-sm resize-none h-8 ml-2 outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={handleSaveChange}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Loading </div>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              arialLabel="loading-indicator"
                            />
                          </div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
