import dayjs from "dayjs";
import { useEffect } from "react";

const StopDetailsCard = (props) => {
  const value = props.value;

  return (
    <div
      key={value.markerId}
      style={{
        border: "1px solid black",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        width: "200px",
        backgroundColor: "white",
        marginTop: 8,
        height: "148px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          fontSize: "0.8rem",
          paddingLeft: "4%",
        }}
      >
        <div>
          {value.customerName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          )}
        </div>
        <div>{value.address.substring(0, 22)}...</div>

        <div>
          {(value.stopNickname
            ? value.stopNickname
            : value.stopObject
            ? value.stopObject.stopNickname
            : value.name
          )
            .substring(0, 22)
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
          ..
        </div>

        {!value.assigned && <div>Unassigned</div>}

        {!value.assigned && <div>Unscheduled</div>}

        {value.assigned && (
          <div>
            {value.employeeName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            )}
          </div>
        )}

        {value.assigned && (
          <div style={{ display: "flex" }}>
            <div>{dayjs(props.selectedDate).format("MM/DD/YYYY")}</div>
            <div style={{ marginLeft: "4%" }}>
              {dayjs(props.selectedDate).format("ddd")}
            </div>
          </div>
        )}
        <div
          onClick={() => props.handleViewDetails(value)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          View Details
        </div>
        <div
          onClick={() =>
            props.handleViewDay(
              props.dayData[value.contractor.id],
              value.contractor,
              value.completedDates
            )
          }
          style={{ color: "blue", cursor: "pointer" }}
        >
          View Route
        </div>
      </div>
    </div>
  );
};

export default StopDetailsCard;
