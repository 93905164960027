import React, { useState, useEffect, useRef } from "react";
import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";

const CheckBox = (props) => {
  const [checked, setChecked] = useState(false);
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;

    if (isMounted.current) {
      setChecked(props.checked);
    }

    return () => (isMounted.current = false);
  }, [props.checked]);

  return (
    <div
      onClick={() =>
        props.disabled
          ? console.log()
          : props.id
          ? props.onChange(props.id)
          : props.onChange()
      }
    >
      {checked ? <BiCheckboxSquare size={22} /> : <BiCheckbox size={22} />}
    </div>
  );
};

export default CheckBox;
