import firebase from "firebase/compat/app";

export const getMediaStream = (mediaId) => {
  return new Promise((resolve) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/getMediaUrl",
          {
            method: "POST",
            body: JSON.stringify({
              tokenId: token,
              mediaId: mediaId,
            }),
          }
        )
          .then((response) => response.json())
          .then((json) => {
            resolve(json.mediaUrl);
          })
          .catch((e) => console.log(e));
      });
  });
};
