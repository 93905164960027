import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import EmployeeCard from "./EmployeeCard";
import CalendarStrip from "./CalendarStrip";
import { BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";
import { FiAlertOctagon } from "react-icons/fi";
import EditRouteMenu from "./EditRouteMenu";

const SideMenu = (props) => {
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [allEmployees, setAllEmployees] = useState([]);
  const [unAssignedServiceLocation, setUnAssignedServiceLocations] = useState(
    []
  );
  const [refresh, setRefresh] = useState(0);
  const [dayViewDisplay, setDayViewDisplay] = useState(false);
  const [dayViewData, setDayViewData] = useState({});
  const [stopViewDisplay, setStopViewDisplay] = useState(false);
  const [stopViewData, setStopViewData] = useState({});
  const [unassignedCount, setUnassignedCount] = useState(0);
  const routeIsAlreadyActive = props.routeIsAlreadyActive;
  const db = firebase.firestore();

  useEffect(() => {
    const databaseSnapshot = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .onSnapshot((snapshots) => {
        let arrOfEmployees = [];
        setAllEmployees(arrOfEmployees);
        snapshots.docs.forEach((value) => {
          const data = value.data();
          arrOfEmployees.push(data);
          setAllEmployees(arrOfEmployees);
          props.setEmployeesInRoute(arrOfEmployees);
          setRefresh(Math.random(0, 2));
        });
      });

    return () => databaseSnapshot();
  }, []);

  useEffect(() => {
    let arrOfServiceLocations = [];

    // Resetting the unassigned service locations and count to initial state
    setUnAssignedServiceLocations([]);
    setUnassignedCount(0);

    for (let index = 0; index < props.serviceLocationsInRoute.length; index++) {
      const value = props.serviceLocationsInRoute[index];
      if (!value.assigned) {
        const stopCount = value.recurringStopType.stopIntervalPerWeek;

        for (let index = 0; index < stopCount; index++) {
          arrOfServiceLocations.push(value);
        }

        setUnAssignedServiceLocations(arrOfServiceLocations);
        setUnassignedCount(arrOfServiceLocations.length);
        setRefresh(Math.random());
      } else {
        const stopCount = value.recurringStopType.stopIntervalPerWeek;
        const markers = props.markers;
        const serviceLocationId = value.serviceLocationId;

        const assignedStopCount = markers.filter(
          (marker) =>
            marker.assigned && marker.serviceLocationId === serviceLocationId
        ).length;

        if (assignedStopCount < stopCount) {
          const addCount = stopCount - assignedStopCount;

          for (let index = 0; index < addCount; index++) {
            arrOfServiceLocations.push(value);
          }
        }
      }
    }

    setUnAssignedServiceLocations(arrOfServiceLocations);
    setUnassignedCount(arrOfServiceLocations.length);
    setRefresh(Math.random());
  }, [props.serviceLocationsInRoute, props.refresh]);

  const handleViewDay = (data) => {
    setDayViewDisplay(true);
    setDayViewData(data);
  };

  const handleGoBackDayView = () => {
    setDayViewDisplay(false);
    setDayViewData({});
  };

  const handleViewStop = (data) => {
    setStopViewData(data);
    setStopViewDisplay(true);
  };

  const handleGoBackStopView = () => {
    setStopViewData({});
    setStopViewDisplay(false);
  };

  return (
    <div>
      <div
        className="hideScroll"
        style={{
          width: "auto",
          position: "absolute",
          zIndex: 2000,
          marginLeft: 20,
          marginTop: "10px",
          marginBottom: "10px",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="hideScroll"
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #E5E7EB",
              borderRadius: "0.5rem",
              backgroundColor: "white",
              height: "60vh",
              overflowY: "scroll",
              width: "280px",
            }}
          >
            {allEmployees.map((value) => (
              <EmployeeCard
                setEmployeePhotos={props.setEmployeePhotos}
                value={value}
                handleEditMode={props.handleEditMode}
                editModeDay={props.editModeDay}
                selectedBusiness={selectedBusiness}
                selectedRoute={selectedRoute}
                handleToggleHiddenDay={props.handleToggleHiddenDay}
                hiddenDays={props.hiddenDays}
                handleViewDay={handleViewDay}
                key={value.uid}
                handleCancelEditMode={props.handleCancelEditMode}
                handleToggleHiddenEmployee={props.handleToggleHiddenEmployee}
              />
            ))}
          </div>
        </div>
      </div>
      {dayViewDisplay && (
        <div
          style={{
            width: "280px",
            border: "1px solid #E5E7EB",
            borderRadius: "0.5rem",
            backgroundColor: "white",
            height: "50vh",
            position: "absolute",
            zIndex: 2000,
            marginTop: "0.6%",
            overflowY: "scroll",
            marginLeft: 20,
          }}
          class="routeBuilderEditSideMenuScroll"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "4%",
            }}
          >
            <div
              onClick={() => handleGoBackDayView()}
              style={{
                marginLeft: "4%",
                cursor: "pointer",
              }}
            >
              <BsChevronLeft size={22} />
            </div>
            <div style={{ marginLeft: "19%" }}>
              <img
                className="inline-block h-10 w-10 rounded-full"
                src={dayViewData.employeeData.photo}
              />
            </div>
            <div style={{ marginLeft: "2%", fontSize: "0.8rem" }}>
              <div>
                {dayViewData.employeeData.name.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "3%",
              marginTop: "2%",
            }}
          >
            <div
              style={{
                backgroundColor: dayViewData.color,
                height: 20,
                width: 20,
                color: "white",
                display: "flex",
                justifyContent: "center",
                margin: 6,
                alignItems: "center",
                border: "1px solid #E5E7EB",
                borderRadius: "0.5rem",
                fontSize: "0.8rem",
              }}
            >
              {dayViewData.count}
            </div>
            <div style={{ marginLeft: "1%", fontSize: "0.8rem" }}>
              {dayViewData.day}
            </div>
          </div>
          <div style={{ marginTop: "4%" }}>
            {dayViewData.dayData.map((value, index) => (
              <div
                key={value.serviceLocationId}
                onClick={() => handleViewStop(value)}
                style={{
                  height: 46,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: "6%",
                  marginBottom: "6%",
                }}
              >
                <div
                  style={{
                    marginLeft: "3.6%",
                    fontSize: "0.8rem",
                    width: "8%",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "2.8%",
                    fontSize: "0.8rem",
                    width: "80%",
                  }}
                >
                  <div>
                    {value.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      textAlign: "left",
                      width: "80%",
                    }}
                  >
                    {value.recurringStopType.stopName}
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "4%",
                    fontSize: "0.8rem",
                  }}
                >
                  {value.recurringStopType.recurringSchedule}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {stopViewDisplay && (
        <div
          style={{
            width: "280px",
            border: "1px solid #E5E7EB",
            borderRadius: "0.5rem",
            backgroundColor: "white",
            height: "50vh",
            position: "absolute",
            zIndex: 2000,
            marginTop: "0.6%",
            overflowY: "scroll",
            marginLeft: 20,
          }}
          class="routeBuilderEditSideMenuScroll"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "4%",
            }}
          >
            <div
              onClick={() => handleGoBackStopView()}
              style={{
                marginLeft: "4%",
                cursor: "pointer",
              }}
            >
              <BsChevronLeft size={22} />
            </div>
            <div style={{ marginLeft: "32%", fontSize: "0.8rem" }}>
              {stopViewData.name}
            </div>
          </div>
          <div style={{ marginTop: "8%" }}>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8rem",
              }}
            >
              <div>Customer:</div>
              <div>{stopViewData.customerId.substring(0, 28)}...</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8rem",
              }}
            >
              <div>Address:</div>
              <div>{stopViewData.address.substring(0, 28)}...</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8rem",
              }}
            >
              <div>Stop Title:</div>
              <div>{stopViewData.recurringStopType.stopName}</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8rem",
              }}
            >
              <div>Frequency:</div>
              <div>{stopViewData.recurringStopType.recurringSchedule}</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8rem",
              }}
            >
              <div>Service Day:</div> <div>{dayViewData.day}</div>
            </div>
            <div
              style={{
                margin: "4%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8rem",
              }}
            >
              <div>Employee:</div> <div>{stopViewData.employeeUid}</div>
            </div>
          </div>
        </div>
      )}

      {!props.editMode && (
        <EditRouteMenu
          selectedRoute={selectedRoute}
          selectedBusiness={selectedBusiness}
          handleAddCustomersClick={props.handleAddCustomersClick}
          handleActivateRouteClick={props.handleActivateRouteClick}
          handleEmployeesClick={props.handleEmployeesClick}
          handleCloseActiveRouteClick={props.handleCloseActiveRouteClick}
          handleCloseRouteClick={props.handleCloseRouteClick}
          handleRouteSave={props.handleRouteSave}
          hasUnactivatedChanges={props.hasUnactivatedChanges}
          serviceLocationsInRoute={props.serviceLocationsInRoute}
          routeStartDate={props.routeStartDate}
          setHasUnactivatedChanges={props.setHasUnactivatedChanges}
          routeIsAlreadyActive={routeIsAlreadyActive}
          setDisplayChangesMenu={props.setDisplayChangesMenu}
        />
      )}

      {props.hasUnactivatedChanges && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            right: 420,
            top: 24,
            zIndex: 10,
          }}
        >
          <FiAlertOctagon color="red" size={20} />
          <div style={{ marginLeft: 10, color: "red", fontSize: "0.8rem" }}>
            This route has unactivated changes!
          </div>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
